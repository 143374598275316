import axios from 'axios';

console.log('process.env.REACT_APP_HOST', process.env.REACT_APP_HOST);
const RODX_API_HOST = process.env.REACT_APP_HOST;
console.log('RODX_API_HOST', RODX_API_HOST);

console.log('process.env.REACT_APP_PORTAL_API_HOST', process.env.REACT_APP_PORTAL_API_HOST);
const PORTAL_API_HOST = process.env.REACT_APP_PORTAL_API_HOST;
console.log('PORTAL_API_HOST', PORTAL_API_HOST);

console.log('process.env.REACT_APP_API_KEY', process.env.REACT_APP_API_KEY);
console.log('process.env.REACT_APP_AUTH_DOMAIN', process.env.REACT_APP_AUTH_DOMAIN);
console.log('process.env.REACT_APP_PROJECT_ID', process.env.REACT_APP_PROJECT_ID);
console.log('process.env.REACT_APP_STORAGE_BUCKET', process.env.REACT_APP_STORAGE_BUCKET);
console.log('process.env.REACT_APP_MESSAGING_SENDER_ID', process.env.REACT_APP_MESSAGING_SENDER_ID);
console.log('process.env.REACT_APP_APP_ID', process.env.REACT_APP_APP_ID);
console.log('process.env.REACT_APP_MEASUREMENT_ID', process.env.REACT_APP_MEASUREMENT_ID);

export const firebaseAppConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};

console.log('firebaseAppConfig', firebaseAppConfig);

export const apiPortal = axios.create({
    baseURL: PORTAL_API_HOST,
    timeout: 30000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
    },
});
