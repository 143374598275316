import React, { useEffect, useState } from 'react';

import { Box, styled, Grid, Typography, useTheme, Button } from '@mui/material';

import { useError } from 'contexts/ErrorContext';
import { useLocations } from 'services/location';
import { useCompany, actions as CompanyActions } from 'services/company';

import CompanyInfo from './content/Company';
import Repairers from './content/Repairers';

import { isProd } from 'helpers/ProdDetect';

import './styles.css';
const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    padding: 0,
    width: '100%',
}));

const AdminScreen = () => {
    const theme = useTheme();
    const errorContext = useError();

    // Tabs
    const pills = ['Company Info', 'Repairers'];
    const [currentTab, setCurrentTab] = useState('Company Info');

    const handlePillClick = function (pill) {
        setCurrentTab(pill);
    };

    // Locations
    const locationContext = useLocations();
    const [locationsLoaded, setLocationsLoaded] = useState(false);
    const reloadLocations = (locContext, errContext, onLocationsLoaded) => {
        if (!locationsLoaded) {
            locationContext.actions.refreshLocations({}, errContext, onLocationsLoaded);
        }
    };

    useEffect(
        () => reloadLocations(locationContext, errorContext, () => setLocationsLoaded(true)),
        [locationsLoaded],
    );

    // Company
    const companyContext = useCompany();
    const [companyLoaded, setCompanyLoaded] = useState(false);
    const reloadCompany = (compContext, errContext, onCompanyLoaded) => {
        if (!companyLoaded) {
            CompanyActions.refreshCompany(compContext, errContext, onCompanyLoaded);
        }
    };

    useEffect(
        () => reloadCompany(companyContext, errorContext, () => setCompanyLoaded(true)),
        [companyLoaded],
    );

    // Tab switch effects
    useEffect(() => {
        if (currentTab === 'Repairers') {
            reloadLocations(locationContext, errorContext, () => setLocationsLoaded(true));
        }
        if (currentTab === 'Company Info') {
            reloadCompany(companyContext, errorContext, () => setCompanyLoaded(true));
        }
    }, [currentTab]);

    const pillBar = function () {
        return pills.map((pill, i) => {
            return (<Grid item key={i}>
                <Button
                    onClick={() => handlePillClick(pill)}
                    sx={{ p: '3px',
                        m: '4px',
                        fontSize: '16px',
                        textTransform: 'none',
                        color: (pill === currentTab) ? theme.palette.blue[500] : theme.palette.grey[500],
                    }}
                    className={(pill === currentTab) ? 'active': ''}
                > { pill } </Button>
            </Grid>);
        });
    };

    const showContent = function () {
        switch (currentTab) {
            case 'Company Info':
                return (<CompanyInfo />);
            case 'Repairers':
                return (<Repairers />);
            default:
                return (<CompanyInfo />);
        }
    };

    return (
        <Grid container display={'flex'} direction={'column'}>
            <Typography variant={'h1'} m={0} mb={1} p={0} pb={1}> Administration </Typography>
            <Grid item display={'flex'} justifyContent={'space-between'}>
                <Box display={'flex'}> { pillBar() } </Box>
            </Grid>
            <Grid item container display={'flex'} my={4}>
                <ContentContainer>
                    { showContent() }
                </ContentContainer>
            </Grid>
        </Grid>
    );
};

export default AdminScreen;
