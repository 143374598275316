import React, { useEffect, useState, useCallback } from 'react';

import debounce from 'lodash.debounce';

import { Box, useTheme } from '@mui/material';

import { useError } from 'contexts/ErrorContext';
import { useRepairers, actions } from 'services/repairer';

import ListAndDetailsPanel from 'components/Panels/ListAndDetailsPanel';

import RepairerList from './Content/RepairerList';
import RepairerDetails from './Content/RepairerDetails';

const Repairers = () => {
    const theme = useTheme();
    const repairersContext = useRepairers();
    const errorContext = useError();

    const [repairersLoaded, setRepairersLoaded] = useState(false);

    const onRepairersLoaded = () => {
        setRepairersLoaded(true);
    };

    const reloadRepairers = (repContext, errContext, onRepairersLoaded) => {
        if (!repairersLoaded) {
            actions.refreshRepairers(
                { filter: repContext.state.filters, textQuery: repContext.state.searchTerm },
                repContext,
                errContext,
                onRepairersLoaded,
            );
        }
    };

    const debouncedReloadRepairers = useCallback(debounce(reloadRepairers, 500), []);

    useEffect(
        () => debouncedReloadRepairers(repairersContext, errorContext, onRepairersLoaded),
        [repairersLoaded],
    );

    return (
        <Box>
            <ListAndDetailsPanel
                list={repairersContext?.state?.repairers || []}
                singularTitle='Repairer'
                pluralTitle='Repairers'
                listElement={RepairerList}
                detailElement={RepairerDetails}
                loaded={repairersLoaded}
                listWidth={3.5}
            />
        </Box>
    );
};

export default Repairers;
