import React, { useState } from 'react';
import { Grid, Paper, Typography, Divider, useTheme } from '@mui/material';

import { useRepairers, TYPES as REPAIRER_TYPES } from 'services/repairer';

import MultiAccordionContainer from 'components/Containers/Accordion/MultiAccordionContainer';

import { useLocations } from 'services/location';
import RepairerDetailsForm from 'components/Repairers/RepairerDetailsForm';
import InvitationStatusForm from './InvitationStatusForm';
import AssignNewLocationModal from './Modals/AssignLocationModal';

const RepairerDetails = () => {
    const theme = useTheme();
    const [assignNewLocationModalOpen, setAssignNewLocationModalOpen] = useState(false);
    const [assignNewLocationModalSuccess, setAssignNewLocationModalSuccess] = useState(false);
    const locationContext = useLocations();
    const repairerContext = useRepairers();
    const activeRepairer = repairerContext?.state?.activeRepairerId ?
        (repairerContext.state.repairers.find((loc) => loc.id === repairerContext.state.activeRepairerId) ?? {}) :
        {};

    // MTODO: handle prop change correctly when API supports it, contact details are read-only so only handle notes
    const handleNotesChange = (prop, val) => {
        if (prop === 'notes') {
            repairerContext.DISPATCH({
                type: REPAIRER_TYPES.EDIT_REPAIRER_NOTES,
                payload: { notes: val },
            });
        }
    };

    let accordions = [
        {
            summary: 'DETAILS',
            content: (
                <RepairerDetailsForm
                    repairer={{ ...activeRepairer?.['contact_info'], notes: activeRepairer?.notes || '' }}
                    handlePropChange={handleNotesChange}
                    showStatus={false}
                    disabledFields={['email', 'phone_number']}
                    cancelBtnDisabled={(activeRepairer?.notes || '').length === 0}
                    submitBtnDisabled={(activeRepairer?.notes || '').length === 0}
                />
            ),
        },
    ];

    if (activeRepairer.registrationCode) {
        accordions = [
            {
                summary: 'DETAILS',
                content: (<InvitationStatusForm repairer={activeRepairer} />),
            },
        ];
    }

    const accordionsDefaultOpenState = {
        'DETAILS' : true,
    };

    let modalElem = null;
    if (assignNewLocationModalOpen) {
        modalElem = (
            <AssignNewLocationModal
                open={assignNewLocationModalOpen}
                locations={(locationContext.state.locations || [])}
                onSubmit={() => setAssignNewLocationModalSuccess(true)}
                onClose={() => {
                    setAssignNewLocationModalOpen(false);
                    setAssignNewLocationModalSuccess(false);
                }}
                success={assignNewLocationModalSuccess}
            />
        );
    }

    return (
        <Paper elevation={0} sx={{ width: '100%' }}>
            {modalElem}
            <Grid container justifyContent={'center'} display={'flex'} sx={{ pb: 4 }}>
                <Grid
                    item
                    sx={{ height: '50px', width: '100%', px: 2 }}
                    alignItems={'center'}
                    display={'flex'}
                    justifyContent={'space-between'}
                >
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}>Details</Typography>
                    <Grid container justifyContent={'right'} display={'flex'}>
                        {/* ADD CONTROL ELEMS HERE IF NEEDED */}
                    </Grid>
                </Grid>
                <Divider sx={{ width: '100%', mb: 1 }} />
                <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid container item xs={8} direction={'column'} spacing={1}>
                        <Grid item>
                            <Typography textAlign={'center'} mt={2.5} sx={{ width: '100%', px: 2, fontSize: 20, fontWeight: 400 }}>
                                { activeRepairer?.name?.length > 0 ?
                                    activeRepairer.name :
                                    ( activeRepairer.registrationCode ? 'Pending Invite' : 'Repairer Nickname')
                                }
                            </Typography>
                        </Grid>
                        <MultiAccordionContainer accordions={accordions} defaultState={accordionsDefaultOpenState} multiExpand />
                    </Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default RepairerDetails;
