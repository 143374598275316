import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Grid, Paper, Typography, useTheme } from '@mui/material';

import InfiniteScroll from 'react-infinite-scroll-component';

import { STATUS_BAR_CLASS, STATUS_BAR_DISPLAY } from 'services/utils/enums';
import { useJobs, TYPES as JOB_TYPES } from 'services/job';
import { useError } from 'contexts/ErrorContext';

import Loading from 'components/Loading';

import JobCard from './JobCard';

const JobList = () => {
    const theme = useTheme();
    const err = useError();
    const jobsContext = useJobs();

    const browserLocation = useLocation();
    const [shouldScrollToJobCard, setShouldScrollToJobCard] = React.useState(true);

    useEffect(() => {
        if (jobsContext.state.activeJob) {
            // Disable auto scroll to card if job is not selected from a deep link (i.e. user clicks on a card)
            setShouldScrollToJobCard(false);
        }
    }, [jobsContext.state.activeJob]);

    // Auto scroll to card if job is selected from a deep link
    useEffect(() => {
        const params = browserLocation.search ? new URLSearchParams(browserLocation.search) : null;
        const jobIdFromURL = params ? params.get('job') : null;

        if (jobIdFromURL) {
            jobsContext.actions.refreshJob(jobIdFromURL).then(() => {
                if (jobIdFromURL) {
                    setShouldScrollToJobCard(true);
                    setActiveCard(jobIdFromURL);
                }
            }).catch((e) => {
                console.error('Error refreshing job', e);
            });
        }
    }, [browserLocation]);

    const formatJob = function (job) {
        // let { created_at, repairer_company, price, location, id, vehicle } = job;
        const getTimestamp = (createdAt) => {
            return new Date(createdAt).toDateString();
            // const hoursSince = Math.abs(new Date() - new Date(createdAt)) / 3600000;
            // if (hoursSince <= .05) {
            //     return 'New';
            // }
            // if (hoursSince <= 1) {
            //     return `${Math.floor(hoursSince*60)}mins ago`;
            // }
            // if (hoursSince > 1 && hoursSince <= 24) {
            //     return `${Math.floor(hoursSince)}hrs ago`;
            // }
            // if (hoursSince > 24 && hoursSince <= (24*30)) {
            //     return `${Math.floor(hoursSince/24)}days ago`;
            // }
            // if (hoursSince > (24*30) && hoursSince <= (24*365)) {
            //     return `${Math.floor(hoursSince/24/30)}months ago`;
            // }
            // return `${Math.floor(hoursSince/24/30/365)}years ago`;
        };

        const getJobStatus = function (services) {
            // @ts-ignore
            const s = [...new Set(services.map(el => STATUS_BAR_DISPLAY[el.service_status]))].map((el) => {
                return {
                    status: el,
                    statusStyle: STATUS_BAR_CLASS[el],
                };
            });
            return s;
        };
        if (job) {
            return {
                timestamp: getTimestamp(job.created_at),
                jobStatuses: getJobStatus(job.services),
                color: job.vehicle.color || '',
                location: job.location.nickname,
                // @ts-ignore
                repairerList: [...new Set(job.services.map(el => el.repair_company.name))].join(', '),
                // @ts-ignore
                serviceList: [...new Set(job.services.map(el => el.service_details.service_type))].join(', '),
                numServices: job.services.length, // number of services on the job
                total: job.services.reduce((sum, part) => sum += part.price, 0),
                currency: (job.currency) ? job.currency.abbreviation : 'USD', // ISO currency code
                stockRO: job.vehicle.stock_number || '',
                vin: job.vehicle.vin || '',
                year: job.vehicle.year || '',
                make: job.vehicle.make || '',
                model: job.vehicle.model || '',
                id: job.id,
            };
        }
    };

    const setActiveCard = async function (id) {
        console.log(JOB_TYPES.SET_ACTIVE_JOB, id);
        jobsContext.DISPATCH({ type: JOB_TYPES.SET_ACTIVE_JOB, payload: id });
    };

    const jobCards = jobsContext.state.jobs.map((job, i) => {
        return (
            <JobCard
                data={formatJob(job)}
                key={i}
                job={job}
                state={jobsContext.state}
                setActiveCard={setActiveCard}
                shouldScrollToJobCard={shouldScrollToJobCard}
            />
        );
    });

    return (
        <Grid
            id='job-list-scrollable-paper'
            item
            xs={3}
            sx={{ borderRight: '1px solid', borderColor: theme.palette.grey[50] }}
            overflow={'auto'}
        >
            <Paper elevation={0} sx={{ backgroundColor: theme.palette.background.paper }}>
                {
                    !(jobsContext?.state?.loaded || false) ? (
                        <Loading loading fullScreen={false} width={150} height={150} />
                    ): (
                        <Paper sx={{ backgroundColor: theme.palette.background.paper, pb: 4 }}>
                            <InfiniteScroll
                                dataLength={jobsContext.state?.jobs?.length || 0}
                                next={() => {
                                    jobsContext.actions.loadMoreJobs(jobsContext.state.paging.next_page);
                                }}
                                hasMore={jobsContext.state?.paging?.next_page != null}
                                scrollableTarget='job-list-scrollable-paper'
                                loader={<Loading loading fullScreen={false} width={150} height={150} />}
                                endMessage={
                                    <Typography style={{ textAlign: 'center', marginTop: '10px' }}>
                                        All jobs loaded
                                    </Typography>
                                }
                            >
                                {jobCards}
                            </InfiniteScroll>
                        </Paper>
                    )
                }
            </Paper>
        </Grid>
    );
};

JobList.propTypes = {
};

export default JobList;