// @ts-nocheck
// React
import React, { useEffect, useState } from 'react';
// Local
import { useAuth } from 'services/auth';
import { useNavigate, useLocation } from 'react-router-dom';
import { Formik } from 'formik';
import * as yup from 'yup';
// Styles
import { Link, Box, Grid, Typography, InputAdornment, IconButton, OutlinedInput, InputLabel, FormControl, FormHelperText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import styles from './styles';

import logo from '../../assets/images/logo.svg';

const LoginScreen = () => {
    const browserLocation = useLocation();
    const [loading, setLoading] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);
    const [passwordTouched, setPasswordTouched] = useState(false);

    const navigate = useNavigate();
    const auth = useAuth();

    // login using email/password on form submit
    const handleSubmit = ({ email, password }) => {
        setLoading(true);

        auth.actions.login(email, password);
    };

    useEffect(() => {
        if (auth.state.email) {
            setLoading(false);
        }
    }, [auth.state.email]);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    const handleMouseUpPassword = (event) => {
        event.preventDefault();
    };

    const isReadyToSubmit = (errors) => {
        return emailTouched && !errors.email && passwordTouched && !errors.password;
    };

    return (
        <>
            <Grid container item direction='column' alignItems='center' justifyContent='center'>
                <img
                    src={logo}
                    style={styles.logoImg}
                />
                <Typography align='center' variant='h1' sx={styles.logoText}>
                    Sign In
                </Typography>
            </Grid>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                validateOnChange={true}
                validateOnBlur={false}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit} style={{ minWidth: '100%' }}>
                        <Box display='flex' alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap='20px'>
                            <FormControl variant='outlined' error={!!touched.email && !!errors.email} fullWidth disabled={loading}>
                                <InputLabel htmlFor='outlined-email'>Enter Email</InputLabel>
                                <OutlinedInput
                                    id='outlined-email'
                                    label='Enter Email'
                                    inputProps={{
                                        onBlur: handleBlur,
                                    }}
                                    onChange={e => {
                                        setEmailTouched(true);
                                        handleChange(e);
                                    }}
                                    value={values.email}
                                    name='email'
                                    type='text'
                                    aria-describedby='email-helper-text'
                                />
                                <FormHelperText
                                    id='email-helper-text'
                                >
                                    {touched.email && errors.email}
                                </FormHelperText>
                            </FormControl>
                            <FormControl variant='outlined' error={!!touched.password && !!errors.password} fullWidth disabled={loading}>
                                <InputLabel htmlFor='outlined-adornment-password'>Enter Password</InputLabel>
                                <OutlinedInput
                                    id='outlined-adorment-password'
                                    label='Enter Password'
                                    inputProps={{
                                        onBlur: handleBlur,
                                    }}
                                    onChange={e => {
                                        setPasswordTouched(true);
                                        handleChange(e);
                                    }}
                                    value={values.password}
                                    name='password'
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position='end'>
                                            <IconButton
                                                aria-label='toggle password visibility'
                                                onClick={handleClickShowPassword}
                                                onMouseDown={handleMouseDownPassword}
                                                onMouseUp={handleMouseUpPassword}
                                                edge='end'
                                                disabled={loading}
                                            >
                                                {showPassword ? <Visibility style={styles.svg} /> : <VisibilityOff style={styles.svg} />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    aria-describedby='password-helper-text'
                                />
                                <FormHelperText
                                    id='password-helper-text'
                                >
                                    {touched.password && errors.password}
                                </FormHelperText>
                            </FormControl>
                            <LoadingButton
                                type='submit'
                                color='secondary'
                                variant={isReadyToSubmit(errors) ? 'contained' : 'outlined'}
                                loading={loading}
                                fullWidth
                                sx={{
                                    borderRadius: '8px',
                                    borderWidth: '1px',
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                    fontWeight: '700',
                                    fontSize: '14px',
                                    height: '40px',
                                }}
                            >
                                SIGN IN
                            </LoadingButton>
                            <Link
                                component='button'
                                type='button'
                                disabled={loading}
                                sx={styles.forgotPassword}
                                onClick={() => { navigate('forgot_password'); }}>
                                Forgot Password?
                            </Link>
                        </Box>
                    </form>
                )}
            </Formik>
        </>
    );
};

const initialValues = {
    email: '',
    password: '',
};

const checkoutSchema = yup.object().shape({
    email: yup.string().email('invalid email').required('required'),
    password: yup.string().required('required'),
});

export default LoginScreen;
