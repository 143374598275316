export const colors = {
    black: '#363636',
    blue: '#1f74db',
    green: '#0A891B',
    grey: '#CAD2D9',
    blackBackground: '#010319',
    red: '#F04240',
    lightGrey: 'rgba(250, 250, 250)',
    blackTransparent: 'rgba(0, 0, 0, 0.6)',
};

export const textSize = {
    default: '16px',
};

export const footer = {
    textAlign: 'right',
    background: colors.lightGrey,
    color: colors.black,
    padding: 10,
    fontSize: 13,
};

const styles = {
    footer,
    colors,
    h1: {
        textAlign: 'center',
    },
    label: {
        whiteSpace: 'nowrap',
        display: 'inline-block',
        color: colors.blackText,
        fontSize: textSize.default,
        textAlign: 'end',
    },
    input: {
        textAlign: 'left',
        outline: 'none',
        border: 'none',
        color: colors.black,
        padding: '10px',
        background: '#fafafa',
    },
    container: {
        color: colors.black,
        outline: 'none',
        background: 'white',
        width: '100vw',
        height: '100vh',
        overflow: 'hidden',
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
    },
    row: {
        display: 'flex',
        flexDirection: 'row',
    },
    submit: {
        background: colors.blue,
        color: 'white',
        borderRadius: '5px',
        marginTop: '30px',
        cursor: 'pointer',
        outline: 'none',
        border: 'none',
        alignSelf: 'center',
        maxWidth: '250px',
        textAlign: 'center',
        padding: '10px 50px',
        fontSize: '15px',
    },
    formGroup: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
        borderLeft: 'solid 2px white',
        paddingTop: '3px',
        paddingBottom: '3px',
        paddingLeft: '10px',
        verticalAlign: 'middle',
        textAlign: 'center',
    },
};

export default styles;
