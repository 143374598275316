// @ts-nocheck
// React
import React, { useState, useEffect } from 'react';
import {
    useNavigate,
} from 'react-router-dom';
// Local
import axios from 'axios';
import { ReactComponent as DeleteOutlinedIcon } from '../../../assets/images/trash_red.svg';
import EditServiceModal from './EditServiceModal';
import { useJobs } from 'services/job';
import { useNewJob } from 'services/reducers/newJob';
import { useError } from 'contexts/ErrorContext';
import { createJob } from 'services/job/api';
import { attachMediaToService } from 'services/actions/service';
// Styles
import {
    Button,
    Card,
    Divider,
    Grid,
    Paper,
    Typography,
    useTheme,
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const NewJobCart = () => {
    const theme = useTheme();
    const newJob = useNewJob();
    const jobsContext = useJobs();
    const navigate = useNavigate();
    const err = useError();
    const [editing, setEditing] = useState([]);
    const [submitting, setSubmitting] = useState(false);

    const handleClick = async function (s, i) {
        await newJob.DISPATCH({ type: 'SET_EDIT_ID', payload: s.id });
        let t = editing;
        t[i] = true;
        await setEditing(t);
    };

    const resetEditing = async function () {
        const t = newJob.state.services.map((el, i) => false);
        await setEditing(t);
    };

    const validateData = async function() {
        let totalNumServices = 0;
        let formattedServices = [];
        let formattedJob = {};
        for ( let service of newJob.state.services ) {
            totalNumServices += service.numberOfServices;
            // formattedServices.push({
            //     'id': Number(service.id),
            //     'repairer_company_id': service.repairer.id,
            //     price: Number(service.price),
            //     submission: [
            //         ...service.note.filter(n => n.serviceID == service.id),
            //         ...service.media.filter(m => m.serviceID == service.id).map((el) => {
            //             return { 'caption': el.caption, 'media_type': el.media_type }; 
            //         }),
            //     ],
            //     // notes: (i===0) ? [{ note: service.note }] : [],
            //     // media: (i===0) ? service.media.map((el) => { return { 'caption': el.caption, 'media_type': el.media_type }; }) : [],
            // });
            service.ids.split(',').forEach((el, i) => formattedServices.push({
                'service_details': {
                    id: Number(el),
                },
                'repairer_company_id': service.repairer.id,
                price: Number(service.priceArray.data.filter(p => p.id == el)[0].price),
                media: [...service.note.filter(n => n.serviceID == el), ...service.media.filter(m => m.serviceID == el).map((el) => { return { 'text': el.text, 'media_type': el.media_type }; })],
                // notes: (i===0) ? [{ note: service.note }] : [],
                // media: (i===0) ? service.media.map((el) => { return { 'caption': el.caption, 'media_type': el.media_type }; }) : [],
            }));
        }
        formattedJob = {
            'services': formattedServices,
            'location_id': newJob.state.location.id,
            'po_number': newJob.state.poNumber,
            'vehicle': {
                'vin': newJob.state.vin,
                'make': newJob.state.make,
                'model': newJob.state.model,
                'year': newJob.state.year,
                'color': newJob.state.color,
                'odometer': {
                    'value': Number(newJob.state.odometer),
                    'unit': 'km',
                },
                'stock_number': newJob.state.stockRO,
            },
        };
        // check if passes all "isValid" tests
        const allServicesMapped = (totalNumServices === formattedServices.length);
        const validLocation = Boolean(newJob.state.location.id);
        const validVIN = Boolean(newJob.state.vin);
        // TO DO: ADD VIN CHECKER
        if (allServicesMapped && validLocation && validVIN) {
            return formattedJob;
        } else return false;
    };

    const handleClose = async function(jobId) {
        setSubmitting(false);

        // reload jobs before redirecting, so that the new job is in the list
        jobsContext.DISPATCH({ type: 'CLEAR_FILTERS_SEARCH_SORT' });
        jobsContext.actions.loadJobs({ }, err);

        newJob.DISPATCH({ type: 'RESET_SERVICES' });
        newJob.DISPATCH({ type: 'RESET_JOB' });

        const redirectPath = jobId?.length ? `?job=${jobId}` : '';
        navigate(`/jobs${redirectPath}`);
    };

    const submitService = async function() {
        setSubmitting(true);
        const validJob = await validateData();
        if (validJob) {
            try {
                const media = newJob.state.services.map(s => s.media).flat();

                const response = await createJob({ data: validJob });
                const jobId = response.data.payload.id;

                if (response && response.data.status === 200 && response.data.payload) {
                    if (media && media.length) {
                        let mediaUploads = [];
                        for (const servEl of response.data.payload.services) {
                            if (servEl.uploadUrls && servEl.uploadUrls.length) {
                                servEl.uploadUrls.forEach((uurl) => {
                                    const mFile = media.filter((el) => (el.media_type != 'Text' && el.media_type === uurl.type && el.text === uurl.text));
                                    if (mFile && mFile.length) {
                                        // UPLOAD TO AWS PROMISE
                                        mediaUploads.push(
                                            axios.put(uurl.uploadUrl, mFile[0].data, { headers: { 'Content-Type': mFile[0].contentHeader } }),
                                        );
                                    }
                                });
                                // PATCH SERVICE WITH MEDIA
                                // TODO: NEED TO CHECK THE ID AND SERVICE_ID OF MEDIA AS WELL AS MEDIA TYPE AND CONFIRM UPLOADING CORRECTLY
                                mediaUploads.push(
                                    attachMediaToService({ serviceID: servEl.id, data: { media: servEl.uploadUrls.map(el => { return { id: el.id }; } ) } }),
                                );
                            }
                        }
                        let current = 1;
                        const loadingBarStatus = function(current, max) {
                            console.log(`Promise ${current} of ${max} completed/uploaded...`);
                        };
                        await Promise.all(mediaUploads.map((el) => el.then(() => loadingBarStatus(current++, mediaUploads.length))));
                    }
                    // @ts-ignore
                    err.DISPATCH({ type: 'NO_ERROR', payload: { alert: 'Job created' } });
                    handleClose(jobId);
                }
            } catch (error) {
                setSubmitting(false);
                // @ts-ignore
                console.error(error);
                err.DISPATCH({ type: 'API_ERROR', payload: error });
            }
        } else {
            setSubmitting(false);
            // @ts-ignore
            err.DISPATCH({ type: 'INVALID_INPUT', payload: { alert: 'invalid input' } });
        }
    };

    useEffect(() => {
        if (newJob.state.services && newJob.state.services.length && !editing.length) resetEditing();
    }, [editing, newJob.state, submitting]);

    const displayCerviceCards = function () {
        return newJob.state.services.map((service, i) => {
            return (
                <Card sx={{ p: 1, m: 0 }} key={service.id}>
                    <Grid alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                        <Grid item xs={8}>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 14 }}>
                                { `${service.type} - ${service.repair}` }
                            </Typography>
                            <Typography sx={{ color: theme.palette.grey[700], fontSize: 12 }}>
                                { `${service.numberOfServices} Part(s)` }
                            </Typography>
                            <Typography sx={{ color: theme.palette.grey[900], fontSize: 12 }}>
                                { `${service.repairer.name}` }
                            </Typography>
                        </Grid>
                        <Grid container item direction={'column'} xs={2}>
                            <Button
                                title={`btn-edt-${i}`}
                                onClick={() => handleClick(service, i)}
                                disabled={editing[i]}
                            >
                                <EditIcon />
                                <EditServiceModal resetEditing={resetEditing} />
                            </Button>
                            <Button
                                title={`btn-del-${i}`}
                                onClick={() => {newJob.DISPATCH({ type: 'RESET_SERVICE' }); newJob.DISPATCH({ type: 'REMOVE_SERVICE', payload: service.id }); resetEditing(); newJob.DISPATCH({ type: 'SET_EDIT_ID', payload: 0 });}}
                            >
                                <DeleteOutlinedIcon sx={{ color: 'red' }} />
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item display={'flex'} justifyContent={'center'} sx={{ my: 1 }}>
                        <Grid container display={'flex'} justifyContent={'space-between'}>
                            {/* <Typography sx={{ color: theme.palette.grey[500], fontSize: 12 }}>
                                {`${new Intl.NumberFormat('en-US', { style: 'currency', currency: service.currency }).format(service.price)} x ${service.numberOfServices}`}
                            </Typography> */}
                            <Typography sx={{ color: theme.palette.grey[500], fontSize: 14 }}>
                                { service.totalDP }
                            </Typography>
                        </Grid>
                    </Grid>
                    <Divider />
                </Card>
            );
        });
    };

    return (
        <Grid item xs={3} mr={5}>
            <Paper sx={{ p:2, m:1 }}>
                <Grid item display={'flex'} justifyContent={'center'}>
                    <Typography sx={{ color: theme.palette.grey[900], fontSize: 18 }}>
                        Summary
                    </Typography>
                </Grid>
                <Grid item display='flex' justifyContent={'center'}>
                    <Button
                        variant='contained'
                        onClick={submitService}
                        disabled={submitting}
                        sx={{
                            backgroundColor: theme.palette.blue[700],
                            color: theme.palette.white,
                            m: 1,
                            p: 1,
                            width: '100%',
                            textTransform: 'none',
                        }}>
                        <Typography sx={{ fontSize: 14, fontWeight: 900 }}>
                            Submit Request
                        </Typography>
                        <Grid item xs={2} />
                        <Typography sx={{ fontSize: 14, fontWeight: 900 }}>
                            {
                                `${(newJob.state.services && newJob.state.services.length)
                                    ?
                                    new Intl.NumberFormat('en-US', { style: 'currency', currency: (newJob.state.services && newJob.state.services.length) ? newJob.state.services[0].currency : 'USD' })
                                        .format(newJob.state.services.reduce((sum, serv) => {
                                            let newSum = sum;

                                            if (serv?.priceArray?.data) {
                                                newSum += serv.priceArray.data.reduce((sum, part) => sum + Number(part.price), 0);
                                            } else {
                                                newSum += Number(serv.price) * serv.numberOfServices;
                                            }

                                            return newSum;
                                        }, 0))
                                    :
                                    '$0.00'}`
                            }
                        </Typography>
                    </Button>
                </Grid>
                <Divider variant='middle' />
                { newJob.state.services && newJob.state.services.length > 0 && displayCerviceCards() }
            </Paper>
        </Grid>
    );
};

NewJobCart.propTypes = {

};

export default NewJobCart;
