import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';

import { MenuList } from '@mui/material';
import { AddLocationAlt, ControlPoint, Settings, Work } from '@mui/icons-material';

import DrawerItem from './DrawerItem';
import DrawerActionItem from './DrawerActionItem';

const actionItems = [
    {
        route: '/new_job',
        title: 'New Job',
        icon: ControlPoint,
    },
];

const menuItems = [
    {
        route: '/jobs',
        title: 'Jobs',
        icon: Work,
    },
    {
        route: '/locations',
        title: 'Locations',
        icon: AddLocationAlt,
    },
    {
        route: '/admin',
        title: 'Admin',
        icon: Settings,
    },
];

const defaultItemMapping =
    {
        location: '/',
        route: '/jobs',
    };

export default function NavMenu() {
    const navigate = useNavigate();
    const location = useLocation();
    const [currentRoute, setCurrentRoute] = useState(location.pathname);

    useEffect(() => {
        setCurrentRoute(location.pathname);
    }, [location]);

    function handleClick(path) {
        setCurrentRoute(path);
        navigate(path);
    }

    const actionElems = actionItems.map(({ route, title, icon }) => (
        <DrawerActionItem
            key={route}
            route={route}
            title={title}
            onClick={handleClick}
            iconComponent={icon}
        />
    ));

    const menuElems = menuItems.map(({ route, title, icon }) => (
        <DrawerItem
            key={route}
            route={route}
            title={title}
            onClick={handleClick}
            selected={(currentRoute === route) || ((currentRoute === defaultItemMapping.location) && (route === defaultItemMapping.route))}
            iconComponent={icon}
        />
    ));

    return (
        <MenuList sx={{ pt: 0 }}>
            {/* TODO: Menu Collapse Button */}
            {/* <MenuItem sx={{ p: 1 }} onClick={() => handleClick()}>
                <ListItemIcon>
                    <div style={{ height: '20px', display: 'inline-block' }}></div>
                    <MenuOpen sx={{ color: theme.palette.grey[700] }} fontSize='small' />
                </ListItemIcon>
            </MenuItem> */}
            { actionElems }
            { menuElems }
        </MenuList>
    );
}

NavMenu.propTypes = {
    route: PropTypes.string,
};