import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';
import { useApp } from '../../contexts/AppContext';

import * as actions from './actions';
import AuthReducer, { INITIAL_STATE, INIT } from './reducer';

const AuthContext = createContext({
    DISPATCH: null,
    state: {},
    actions: {},
});

export const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(AuthReducer, { ...INITIAL_STATE }, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
        actions: {
            login: actions.login(dispatch),
            logout: actions.logout(dispatch),
            refreshToken: actions.refreshToken(dispatch),
            resetPassword: actions.resetPassword(dispatch),
        },
    };

    return (
        <AuthContext.Provider value={value}> {children} </AuthContext.Provider>
    );
};

AuthProvider.propTypes = {
    children: PropTypes.node,
};

export const useAuth = () => useContext(AuthContext);
