import React from 'react';
import { Outlet } from 'react-router-dom';
import { Box, Alert, Grid, Snackbar } from '@mui/material';
import { useError } from 'contexts/ErrorContext';

import styles from './styles';
import logoROD from '../assets/images/logo_rod.svg';

const LoginLayout = () => {
    const err = useError();

    const resetError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        // @ts-ignore
        err.DISPATCH({ type: 'RESET_ERROR' });
    };

    return (
        <>
            <Box display='flex' alignItems={'center'} justifyContent={'flex-start'} flexDirection={'column'} sx={styles.background}>
                <Grid container item alignItems='center' justifyContent='space-between' sx={styles.appBar}>
                    <img
                        src={logoROD}
                        style={styles.rodLogoImg}
                    />
                </Grid>
                { err.state.showSnack && (
                    <Snackbar
                        open={err.state.showSnack}
                        autoHideDuration={6000}
                        onClose={resetError}
                    >
                        <Alert onClose={resetError} severity={err.state.severity} sx={{ width: '100%' }}>
                            {err.state.alert}
                        </Alert>
                    </Snackbar>
                )}
                <Grid container item alignItems='center' justifyContent='center' sx={styles.menu} xs={10} sm={8} md={6} lg={5}>
                    {/* https://reactrouter.com/en/main/components/outlet */}
                    {/* the other routes render inside of <Outlet /> */}
                    <Outlet />
                </Grid>
            </Box>
        </>
    );
};

export default LoginLayout;