import React from 'react';
import PropTypes from 'prop-types';

import { Box, Card, Grid, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import MediaWithPopup, { SUPPORTED_MEDIA_TYPES } from 'components/MediaWithPopup';

import {
    capitalizeWords,
    formatMonthDayYear,
    formatPrice,
    getMediaContentOverallType,
    MODIFIED_BY,
    SERVICE_STATUS,
    SUPPLEMENT_STATUS,
    SUPPLEMENT_TYPE,
    isSame,
} from './utils';

const getMediaContentElem = (content) => {
    let ret = null;

    if (content.media_type === 'Text' && content.text?.length) {
        ret = <Typography sx={{ fontSize: '12px', my: 0, py: 0 }}>
            {content.text}
        </Typography>;
    } else if (SUPPORTED_MEDIA_TYPES.includes(content.media_type) && content.url?.length) {
        ret = <MediaWithPopup url={content.url} title={content.text} type={content.media_type} />;
    } else {
        ret = null;
    }

    return (
        <Grid item key='media-elem'>
            {ret}
        </Grid>
    );
};

const getMediaContentListElem = (mediaContentList) => {
    const contentByType = (mediaContentList || [])
        .reduce((acc, cur) => {
            const currentKey = ['Image', 'Video', 'PDF'].includes(cur['media_type']) ? 'ImageAndVideoAndDocuments' : cur['media_type'];

            const currentVal = [...(acc[currentKey] ?? []), cur];

            return { ...acc, [currentKey]: currentVal };
        }, {});

    const elems = [];

    for (const [contentType, contentList] of Object.entries(contentByType).sort((a, b) => a[0] === 'Text' ? -1 : 1)) {
        elems.push((
            <Grid
                container
                key={contentType}
                direction={contentType != 'Text' ? 'row' : 'column'}
                spacing={1}
                my={0.5}
                py={0}
                maxWidth='400px'
            >
                {contentList.map((c) => getMediaContentElem(c))}
            </Grid>
        ));
    }

    return elems;
};

const getMediaContentForHistoryItem = (historyItem, serviceMediaItems, doneBy, theme) => {
    const mediaElems = [];

    if (historyItem.media_submission_ids?.length > 0) {
        historyItem.media_submission_ids.forEach((mediaId) => {
            if (!serviceMediaItems?.[mediaId]?.['submission_content']) {
                return;
            }

            let mediaContentList = serviceMediaItems[mediaId]['submission_content'];

            let attachmentsType = getMediaContentOverallType(mediaContentList);
            const addedMediaElems = getMediaContentListElem(mediaContentList);

            mediaElems.push(
                <div key={`media-${mediaId}`}>
                    <Typography sx={{ fontSize: 14, fontWeight: 300 }} color={theme.palette.grey[900]}>
                        {`${doneBy} added ${`the following ${attachmentsType?.toLocaleLowerCase() || ''}:` || 'media.'}`}
                    </Typography>
                    {addedMediaElems}
                </div>,
            );
        });
    }

    return mediaElems;
};

const generateCardText = (text, theme) => (
    <Typography key={text} sx={{ fontSize: 14, fontWeight: 300 }} color={theme.palette.grey[900]}>
        {text}
    </Typography>
);

const getCardTitleAndDescription = (historyItem, creationItem, status, doneBy, monthDayYearMsg) => {
    let title = '';
    let description = '';
    const capitalizedStatus = capitalizeWords(status) || 'Changed'; // TOREMOVE: 'Unknown' & handle status not existing, when API is stable
    const statusIs = (refStatus) => isSame(status, refStatus);

    if (creationItem) {
        title = 'Service Created';
        description = `This Service was created by ${doneBy} for ${formatPrice(historyItem.price || 0)} on ${monthDayYearMsg}.`;
    } else if (historyItem.supplement) {
        const supplementStatus = historyItem.supplement.status;
        const supplementType = historyItem.supplement.type;

        if (isSame(supplementStatus, SUPPLEMENT_STATUS.PendingApproval)) {
            title = 'Supplement Requested';
            description = `${doneBy} requested a supplement (${supplementType}) on ${monthDayYearMsg}.`;
        } else if (isSame(supplementStatus, SUPPLEMENT_STATUS.Rejected)) {
            title = 'Supplement Declined';
            description = `${doneBy} declined the ${supplementType} supplement on ${monthDayYearMsg}.`;
        } else if (isSame(supplementStatus, SUPPLEMENT_STATUS.Approved)) {
            if (isSame(supplementType, SUPPLEMENT_TYPE.Rework)) {
                title = 'Rework Submitted';
                description = `${doneBy} submitted a ${supplementType} supplement on ${monthDayYearMsg}.`;
            } else {
                title = 'Supplement Approved';
                description = `${doneBy} approved the ${supplementType} supplement on ${monthDayYearMsg}.`;
            }
        }
    } else if (historyItem.invoice?.status?.length) {
        title = 'Invoice Updated';
        description = `${doneBy} changed the invoice status to '${historyItem.invoice.status}' on ${monthDayYearMsg}.`;
    } else if (statusIs(SERVICE_STATUS.InProgress)) {
        title = `Service ${capitalizedStatus}`;
        description = `${doneBy} changed this service to '${status}' on ${monthDayYearMsg}.`;
    } else if (
        statusIs(SERVICE_STATUS.Accepted) ||
        statusIs(SERVICE_STATUS.Completed) ||
        statusIs(SERVICE_STATUS.Canceled) ||
        statusIs(SERVICE_STATUS.Declined)
    ) {
        title = `Service ${capitalizedStatus}`;
        description = `${doneBy} ${status.toLowerCase()} this service on ${monthDayYearMsg}.`;
    } else {
        // MTODO: unhandled changes
        console.error('Service change not handled:', historyItem);

        if (status) {
            title = `Service Changed to ${capitalizedStatus}`;
            description = `${doneBy} changed this service to '${status}' on ${monthDayYearMsg}.`;

        } else {
            title = `Service ${capitalizedStatus}`;
            description = `${doneBy} changed this service on ${monthDayYearMsg}.`;
        }
    }

    return [title, description];
};

const ServiceHistoryCard = ({ creationItem, historyItem, supplier, repairer, service }) => {
    // MTODO: update according to new changes to history, for example: handle when status does not exist
    const theme = useTheme();

    const createdAt = new Date(historyItem.created_at);
    const status = historyItem.service_status?.toLocaleLowerCase() || '';
    const doneBy = historyItem.modified_by === MODIFIED_BY.supplier ? supplier : repairer;
    const serviceMediaItems = (service.media || []).reduce((acc, cur) => ({ ...acc, [cur['submission_id']]: cur }), {});

    const monthDayYearMsg = ` ${formatMonthDayYear(createdAt)}`;

    // MTODO: How to determine color??
    const useMainColor = creationItem || historyItem.price;

    let [title, description] = getCardTitleAndDescription(historyItem, creationItem, status, doneBy, monthDayYearMsg);

    if (!title) {
        title = 'Service Updated';
    }

    const mediaContent = getMediaContentForHistoryItem(historyItem, serviceMediaItems, doneBy, theme);

    if (!description.length && !mediaContent.length) {
        description = `Service status was changed to '${status}' by ${doneBy} on ${monthDayYearMsg}.`;
    }

    const cardContent = description.length ? [generateCardText(description, theme)] : [];
    cardContent.push(...mediaContent);

    const cardContentElems = cardContent.length < 2 ? cardContent : (
        <List sx={{ listStyleType: 'disc', pl: 2 }}>
            {
                cardContent.map((e, indx) => (
                    <ListItem key={`card-content-${indx}`} sx={{ display: 'list-item', pl: 0, py: 0.5 }}>
                        {e}
                    </ListItem>
                ))
            }
        </List>
    );

    return (
        <Card key={historyItem.created_at} variant='outlined' sx={{
            borderRadius: 2,
            my: 2,
            p: 2,
            backgroundColor: useMainColor ? theme.palette.blue[50] : theme.palette.white,
        }}>
            <Stack direction='row'>
                <Box width='20px'>
                    <CircleIcon style={{
                        marginTop:'12px',
                        marginRight: '12px',
                        fontSize: 11,
                        color: useMainColor ? theme.palette.primary[700] : theme.palette.grey[500] }}
                    />
                </Box>
                <Stack direction='column'>
                    <Grid container direction='row' display='flex' alignContent='center'>
                        <Grid item sm={7} sx={{ display: 'flex', alignContent: 'center' }}>
                            <Typography my={1.5} sx={{
                                color: theme.palette.grey[900],
                                fontSize: 16,
                                fontWeight: 500,
                                lineHeight: '12px',
                                display: 'flex',
                                alignContent: 'center',
                            }}>
                                { title }
                            </Typography>
                        </Grid>
                        <Grid item sm={5} sx={{ display: 'flex', alignContent: 'center' }} container direction='row-reverse'>
                            <Typography sx={{
                                fontSize: 12,
                                fontWeight: 300,
                                lineHeight: '12px',
                                color: theme.palette.grey[500],
                            }}>
                                {createdAt.toLocaleString()}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Stack direction='column'>
                        {cardContentElems}
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    );
};

ServiceHistoryCard.propTypes = {
    creationItem: PropTypes.bool,
    historyItem: PropTypes.shape({
        'created_at': PropTypes.string,
        'media_submission_ids': PropTypes.array,
        'modified_by': PropTypes.oneOf(Object.values(MODIFIED_BY)),
        'price': PropTypes.number,
        'repair_company': PropTypes.string,
        'service_status': PropTypes.string,
    }),
    supplier: PropTypes.string,
    repairer: PropTypes.string,
    service: PropTypes.object,
};

export default ServiceHistoryCard;
