import { ROUTES } from 'services/utils/enums';
import { sendRequest } from '../utils/api';

import { getCompany } from 'services/company/api';

export const getNotifications = async function() {
    let compId;

    const companyRes = await getCompany();

    if (companyRes?.status == 200) {
        if (!companyRes?.data?.payload?.id) {
            throw new Error('Retrieved company data has no id:', companyRes);
        }

        compId = companyRes.data.payload.id;
    }
    else {
        throw new Error('Error while retrieving company data:', companyRes);
    }

    return sendRequest(ROUTES.GET_NOTIFICATIONS, compId);
};

export const markNotificationAsRead = async function(id) {
    return sendRequest(ROUTES.MARK_NOTIFICATION_AS_READ, id);
};

export default {
    getNotifications,
    markNotificationAsRead,
};
