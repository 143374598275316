
import React, { useEffect } from 'react';

import { Paper, useTheme } from '@mui/material';
import { useRepairers, actions } from 'services/repairer';

import Card from 'components/Cards/MainCard';

export default function RepairerList() {
    const theme = useTheme();
    const repairerContext = useRepairers();

    // MTODO: reformat based on actual data shape once API support is added
    const formatRepairer = function (repairer) {
        if (repairer) {
            const repairerLocations = repairer?.locations || [];

            const cardText = repairerLocations.length > 1 ?
                `${repairerLocations.length} Locations` :
                repairerLocations.map((l) => l.name).join(', ');

            return {
                id: repairer.id,
                title: repairer.name || repairer.email,
                subTitle: (repairer?.services || []).join(', '),
                text: cardText,
            };
        }
    };

    const selectRepairer = async function (id) {
        actions.setActiveRepairer(id, repairerContext);
    };

    const repairerCards = repairerContext.state.repairers
        .sort(
            (a, b) => (a.name ?? a.email ?? '').localeCompare(b.name ?? b.email ?? '', undefined, { numeric: true, sensitivity: 'base' }),
        )
        .map((repairer) => (
            <Card
                key={repairer.id}
                content={ formatRepairer(repairer) }
                selected={repairerContext.state.activeRepairerId === repairer.id}
                active={true} // MTODO: set as repairer.active when RoDx API supports location status (active / inactive)
                onClick={selectRepairer}
            />
        ));

    useEffect(() => {
        if (!repairerContext.state.activeRepairerId) {
            selectRepairer(repairerContext.state?.repairers?.[0]?.id);
        }
    }, [repairerContext.state.activeRepairerId]);

    return (
        <Paper
            elevation={0}
            sx={{
                backgroundColor: theme.palette.background.default,
                px: .5,
                pb: 1,
                height: '100%',
                maxHeight: '100%',
                overflowY: 'auto',
            }}
        >
            { repairerCards }
        </Paper>
    );
}
