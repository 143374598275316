import styles from '../../styles';

const colors = {
    ...styles.colors,
    textEnabled: '#808B90',
    textPopulated: '#141E25',
    textError: '#E80604',
    textFocused: '#307FE2',
    textDisabled: '#CAD2D9',
    buttonEnabled: '#307FE2',
    buttonProcessing: '#D6E8FC',
    buttonDisabled: '#E2E8EE',
    backgroundTop: '#ECF0F5',
    backgroundBottom: '#FFFFFF',
};

const incomingEnvelopeImg = {
    fontSize: '64px',
    verticalAlign: 'middle',
    lineHeight: '1',
};

const logoText = {
    marginTop: '24px',
    marginBottom: '24px',
    fontWeight: '400',
    fontSize: '32px',
};

const backButton = {
    color: '#808B90',
};

export default {
    ...styles,
    colors,
    incomingEnvelopeImg,
    logoText,
    backButton,
};
