import React, { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import swManager from '../serviceWorker/swManager';

import { useAuth } from 'services/auth';

import LoginScreen from 'screens/LoginScreen';
import ForgotPasswordScreen from 'screens/ForgotPasswordScreen';
import PasswordResetEmailSentScreen from 'screens/PasswordResetEmailSentScreen';
import JobsScreen from 'screens/JobsScreen';
import NewJobScreen from 'screens/NewJobScreen';
import NotFoundScreen from 'screens/NotFoundScreen';
import AdminScreen from 'screens/AdminScreen';
import LocationsScreen from 'screens/LocationsScreen';

import { NewJobProvider } from 'services/reducers/newJob';

import { useNotifications } from 'services/notification';
// TO REMOVE: only here because API does not save notifications yet
import { createNotification } from 'services/notification/api';

import { getCompany } from 'services/company/api';

import { JobsProvider } from 'services/job';
import { LocationProvider } from 'services/location';
import { CompanyProvider } from 'services/company';
import { RepairerProvider } from 'services/repairer';
import { useApp } from 'contexts/AppContext';

import LoginLayout from './LoginLayout';
import Layout from './Layout';
import AuthenticatedRoute from './AuthenticatedRoute';
import UnauthenticatedRoute from './UnauthenticatedRoute';

import { apiPortal } from 'config';

const Router = () => {
    const auth = useAuth();
    const user = auth.state;
    const app = useApp();
    const notificationContext = useNotifications();

    const newNotificationHandler = async (pushObject) => {
        // Wait for a few seconds and then retrieve notifications from the server, this is because
        // for now we are sending the new notification to the server in PushManager, until RoDx API adds support for persisted notifications

        // TO REMOVE: when RoDx API adds support for persisted notifications
        setTimeout(() => {
            notificationContext.actions.refreshNotifications();
        }
        , 3000);
    };

    useEffect(() => {
        if (user.idToken) {
            apiPortal.defaults.headers.common.Authorization = user.idToken;
        }
    }, [user.idToken]);

    useEffect(() => {
        if (app && user.email) {
            // register service worker if user is logged in
            swManager.initialize(app, newNotificationHandler);
            notificationContext.actions.refreshNotifications();
        } else {
            // MTODO: unregister push notifications if user is logged out
        }
    }, [app, user]);

    return (
        <JobsProvider>
            <CompanyProvider>
                <LocationProvider>
                    <RepairerProvider>
                        <NewJobProvider>
                            <Routes>
                                <Route element={<UnauthenticatedRoute user={user} />}>
                                    {/* all containers render inside of <LoginLayout /> */}
                                    <Route path='/login' element={<LoginLayout />}>
                                        <Route index element={<LoginScreen />} />
                                        <Route path='forgot_password' element={<ForgotPasswordScreen />} />
                                        <Route path='password_reset_email_sent' element={<PasswordResetEmailSentScreen /> } />
                                    </Route>
                                </Route>
                                <Route element={<AuthenticatedRoute user={user} />}>
                                    {/* all containers render inside of <Layout /> */}
                                    <Route path='/' element={<Layout />}>
                                        <Route index element={<JobsScreen />} />
                                        <Route path='new_job' element={ <NewJobScreen /> } />
                                        <Route path='jobs' element={<JobsScreen />} />
                                        <Route path='admin' element={<AdminScreen />} />
                                        <Route path='locations' element={<LocationsScreen />} />
                                    </Route>
                                </Route>
                                <Route path='*' element={<NotFoundScreen />} />
                            </Routes>
                        </NewJobProvider>
                    </RepairerProvider>
                </LocationProvider>
            </CompanyProvider>
        </JobsProvider>
    );
};

export default Router;
