import * as React from 'react';
import PropTypes from 'prop-types';

import {
    useTheme,
    styled,
    Checkbox,
    Typography,
    Popper,
    ClickAwayListener,
    Autocomplete,
    autocompleteClasses,
    ButtonBase,
    InputBase,
    Box,
} from '@mui/material';

import {
    ArrowDropDown as ArrowDropDownIcon,
    CheckBoxOutlineBlankOutlined as CheckBoxOutlineBlankOutlinedIcon,
    CheckBox as CheckBoxIcon,
} from '@mui/icons-material';

import Loading from 'components/Loading';
import IconContainer from 'components/Icons/IconContainer';

export default function FilterButton({
    title,
    options,
    IconComponent,
    onSelect,
    showSelectedCount = false,
    useFirstSelectedOptionAsTitle = false,
}) {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pendingValue, setPendingValue] = React.useState([]);
    const [dropDownTitle, setDropDownTitle] = React.useState(
        useFirstSelectedOptionAsTitle ? options.filter((o) => o.selected)?.[0]?.name || title : (title || '')
    );

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        onSelect(pendingValue);
        setAnchorEl(null);
    };

    React.useEffect(() => {
        setPendingValue(options.filter((o) => o.selected));
        setDropDownTitle(useFirstSelectedOptionAsTitle ? options.filter((o) => o.selected)?.[0]?.name || title : (title || ''));
    }, [options]);

    const open = Boolean(anchorEl);

    const id = open ? `${title}-label` : undefined;

    return (
        <>
            <Box sx={{
                border: '1px solid #CAD2D9',
                borderRadius: '8px',
                padding: '8px',
                paddingRight: '2px',
                width: 'auto',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '32px',
                fontSize: '12px',
            }}>
                <ButtonBase disableRipple aria-describedby={id} onClick={handleClick} sx={{ fontSize: '12px', width: '100%' }}>
                    { !IconComponent ? null : (
                        <Box sx={{ mr: 1 }}>
                            <IconContainer
                                icon={IconComponent}
                                width='15px'
                                height='15px'
                                iconWidth='15px'
                                iconHeight='15px'
                                padding='5px'
                            />
                        </Box>
                    )}
                    <Typography variant='subtitle2' sx={{ fontSize: '12px', color: theme.palette.grey[900], marginRight: '8px' }}>
                        { dropDownTitle }
                    </Typography>
                    {!showSelectedCount ? null : (
                        <Typography
                            variant='subtitle2'
                            sx={{
                                // maxWidth: '30px',
                                overflow: 'hidden',
                                whiteSpace: 'nowrap',
                                textOverflow: 'ellipsis',
                                fontSize: '12px',
                                color: theme.palette.grey[800],
                            }}>
                            {options.filter((o) => o.selected).length ? `+${options.filter((o) => o.selected).length}` : ''}
                        </Typography>
                    )}
                    <ArrowDropDownIcon sx={{ color: 'black', marginLeft: '8px' }} />
                </ButtonBase>
            </Box>
            <Popper
                id={id}
                open={open}
                anchorEl={anchorEl}
                placement='bottom-end'
                sx={{
                    boxShadow: theme.shadows[3],
                    borderRadius: '8px',
                    p: '0px',
                    zIndex: theme.zIndex.modal,
                    backgroundColor: 'white',
                    width: 'max-content',
                }}
            >
                <ClickAwayListener onClickAway={handleClose}>
                    <div>
                        {!options?.length ? (
                            <Loading loading fullScreen={false} width={150} height={150} />
                        ) : (
                            <Autocomplete
                                open
                                value={pendingValue}
                                sx={{ display: 'none' }}
                                multiple
                                onClose={(event, reason) => {
                                    if (reason === 'escape') {
                                        handleClose();
                                    }
                                }}
                                onChange={(event, newValue) => {
                                    setPendingValue(newValue);
                                }}
                                disableCloseOnSelect
                                PopperComponent={PopperComponent}
                                renderTags={() => null}
                                options={options}
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => {
                                    const IconComponent = option.icon;
                                    return (
                                        <li {...props}>
                                            <Box
                                                sx={{
                                                    flexGrow: 1,
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '30px',
                                                }}
                                            >
                                                {
                                                    IconComponent ? (
                                                        <IconContainer
                                                            icon={IconComponent}
                                                            width='15px'
                                                            height='15px'
                                                            iconWidth='15px'
                                                            iconHeight='15px'
                                                            padding='15px'
                                                        />
                                                    ) : (
                                                        <Checkbox
                                                            checked={selected}
                                                            icon={
                                                                <CheckBoxOutlineBlankOutlinedIcon
                                                                    sx={{ opacity: 1, color: theme.palette.blue.main, width: '15px' }}
                                                                />
                                                            }
                                                            checkedIcon={
                                                                <CheckBoxIcon sx={{ opacity: 1, color: theme.palette.blue.main }} />
                                                            }
                                                        />
                                                    )
                                                }
                                                <Typography
                                                    variant='subtitle2'
                                                    sx={{ fontSize: '12px', textTransform: 'capitalize', lineHeight: '1px', width: 'max-content' }}
                                                >
                                                    {option.name}
                                                </Typography>
                                            </Box>
                                        </li>
                                    );
                                }}
                                renderInput={(params) => (
                                    <InputBase
                                        ref={params.InputProps.ref}
                                        inputProps={params.inputProps}
                                    />
                                )}
                            />
                        )}
                    </div>
                </ClickAwayListener>
            </Popper>
        </>
    );
}

FilterButton.propTypes = {
    title: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.object),
    selected: PropTypes.arrayOf(PropTypes.object),
    IconComponent: PropTypes.any,
    onSelect: PropTypes.func,
    multi: PropTypes.bool,
    showSelectedCount: PropTypes.bool,
    useFirstSelectedOptionAsTitle: PropTypes.bool,
};

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
    ['&.MuiAutocomplete-popper']: {
        width: 'fit-content !important',
    },
    [`& .${autocompleteClasses.paper}`]: {
        boxShadow: 'none',
        borderRadius: '8px',
        width: '100%',
    },
    [`& .${autocompleteClasses.listbox}`]: {
        padding: 0,
        [`& .${autocompleteClasses.option}:last-of-type`]: {
            borderBottom: 'none',
        },
        [`& .${autocompleteClasses.option}`]: {
            minHeight: 'auto',
            padding: '2px 10px 2px 2px',
            width: '100%',
            borderBottom: `1px solid  ${theme.palette.grey[50]}`,
        },
    },
    [`&.${autocompleteClasses.popperDisablePortal}`]: {
        position: 'relative',
        top: '20px',
    },
}));

function PopperComponent(props) {
    // eslint-disable-next-line no-unused-vars
    const { disablePortal, anchorEl, open, ...other } = props;
    return <StyledAutocompletePopper {...other} />;
}

PopperComponent.propTypes = {
    anchorEl: PropTypes.any,
    disablePortal: PropTypes.bool,
    open: PropTypes.bool.isRequired,
};
