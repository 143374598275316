import React, { useState, useEffect } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker as MuiDatePicker } from '@mui/x-date-pickers/DatePicker';
import { Box, Button, ClickAwayListener, Grid, Popper, Typography, useTheme } from '@mui/material';
import DateRangeIcon from '@mui/icons-material/DateRange';
import { PickersDay } from '@mui/x-date-pickers/PickersDay';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PropTypes from 'prop-types';

const dayMillies = 86400000;

function formatDate(date) {
    const currentDate = new Date();

    date = new Date(date);

    // Check if it's today
    if (
        date.getDate() === currentDate.getDate() &&
        date.getMonth() === currentDate.getMonth() &&
        date.getFullYear() === currentDate.getFullYear()
    ) {
        return 'Today';
    }

    // Check if it's the current year
    if (date.getFullYear() === currentDate.getFullYear()) {
        return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });
    } else {
        return date.toLocaleDateString('en-US');
    }
}

function Day({ startDate, endDate, setEndDate, setStartDate, dateTimestamp, ...args}) {
    let isSelected = false;
    let isBetween = false;
    let isBeginning = false;
    let isEnd = false;

    const isStartDate = dateTimestamp === startDate;
    const isEndDate = dateTimestamp === endDate;

    if (isStartDate || isEndDate) isSelected = true;

    if (startDate && endDate && (dateTimestamp >= startDate && dateTimestamp <= endDate)) {
        isBetween = true;
    }

    if (dateTimestamp === startDate && endDate) isBeginning = true;

    if (dateTimestamp === endDate && startDate) isEnd = true;

    if (isStartDate && isEndDate) {
        isBeginning = false;
        isEnd = false;
        isBetween = false;
        isSelected = true;
    }

    return (
        // @ts-ignore
        <PickersDay
            onClick={() => {
                if (isStartDate) setEndDate(dateTimestamp);
                if (isEndDate) setStartDate(dateTimestamp);
            }}
            className={isBeginning ? 'begin' : isEnd ? 'end' : isSelected ? 'selected' : isBetween ? 'between' : ''}
            {...args}
        />
    );
}

Day.propTypes = {
    selected: PropTypes.any,
    'data-timestamp': PropTypes.any,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    setEndDate: PropTypes.func,
    setStartDate: PropTypes.func,
    dateTimestamp: PropTypes.number,
};

export default function DateRangePicker({ start, end, onDateRangeChanged, disableFuture = false }) {
    const theme = useTheme();
    const [startDate, setSDate] = useState(start ?? null);
    const [endDate, setEDate] = useState(end ?? null);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const id = open ? 'dateRange-label' : undefined;

    useEffect(() => {
        if (open) {
            return;
        }

        if (!startDate && !endDate) {
            onDateRangeChanged(null, null);
            return;
        }

        if (start !== startDate || end !== endDate + dayMillies) {
            onDateRangeChanged(startDate, endDate ? endDate + dayMillies : endDate);
        }
    }, [open]);

    useEffect(() => {
        if (start && start !== startDate) {
            setSDate(start);
        }
        if (end && end - dayMillies !== endDate) {
            setEDate(end - dayMillies);
        }
    }, [start, end]);

    async function setStartDate(date) {
        setSDate(date);
        if (!endDate) {
            setEDate(date);
        }
    }

    async function setEndDate(date) {
        if (!startDate) {
            setSDate(date);
        }
        setEDate(date);
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const emptyDateLabel = (startDate == null && endDate == null) ? 'Date' : null;
    const beforeDateLabel = (startDate == null) ? `Before ${formatDate(startDate)}` : null;
    const afterDateLabel = (endDate == null) ? `After ${formatDate(endDate)}` : null;
    const betweenDateLabel = startDate === endDate ? formatDate(startDate) : `${formatDate(startDate)} - ${formatDate(endDate)}`;
    const label = emptyDateLabel || beforeDateLabel || afterDateLabel || betweenDateLabel;

    return (
        <Grid
            display={'flex'} alignItems={'center'}
            sx={{
                width: 'auto',
                minWidth: '20%',
                maxWidth: '100%',
                maxHeight: 32,
                borderRadius: 2,
                border: 1.5,
                borderColor: theme.palette.grey[100],
                px: 0,
                mx: 1,
            }}>
            <Box sx={{
                // border: '1px solid #CAD2D9',
                // borderRadius: '8px',
                padding: '8px',
                paddingRight: '2px',
                width: '100%',
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                alignItems: 'center',
                height: '32px',
                fontSize: '12px',
            }}>
                <Button disableRipple aria-describedby={id} onClick={handleClick}
                    sx={{ width: '100%', padding: 0, display: 'flex', '& *': { flexShrink: 0 }, flexShrink: 0 }}
                >
                    <Grid display={'flex'} width={'100%'} justifyContent={'space-evenly'} alignItems={'center'}>
                        <DateRangeIcon sx={{ marginRight: '8px', color: theme.palette.blue.main, opacity: 1 }} />
                        <Typography variant={'subtitle2'}
                            sx={{ textTransform: 'none', fontSize: '12px', color: theme.palette.grey[900] }}
                        >
                            {label}
                        </Typography>
                        <ArrowDropDownIcon sx={{ color: 'black', marginLeft: '8px' }} />
                    </Grid>
                </Button>
            </Box>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <Popper id={id} open={open} anchorEl={anchorEl} placement='bottom-end'>
                    <ClickAwayListener onClickAway={handleClose}>
                        <div>
                            <MuiDatePicker
                                disableFuture={disableFuture}
                                onChange={(date) => {
                                    // 'clear' is pressed, set start and end date to null
                                    if (!date) {
                                        setStartDate(null);
                                        setEndDate(null);
                                        return;
                                    }

                                    // set date depending on selected date and current range
                                    let timestamp = new Date(date.$d).valueOf();
                                    if (!startDate || timestamp < startDate) return setStartDate(timestamp);
                                    else if (timestamp === endDate) return setStartDate(timestamp);
                                    else return setEndDate(timestamp);
                                }}
                                open={open}
                                closeOnSelect={false}
                                inputRef={anchorEl}
                                slotProps={{
                                    popper: { id, anchorEl, placement: 'bottom-end' },
                                    actionBar: {
                                        actions: ['clear'],
                                    },
                                }}
                                slots={{
                                    textField: () => <></>,
                                    day: (args) => Day({
                                        startDate,
                                        endDate,
                                        setEndDate,
                                        setStartDate,
                                        dateTimestamp: args['data-timestamp'],
                                        ...args,
                                    }),
                                }}
                            />
                        </div>
                    </ClickAwayListener>
                </Popper>
            </LocalizationProvider>
        </Grid>
    );
}

DateRangePicker.propTypes = {
    onDateRangeChanged: PropTypes.func.isRequired,
    start: PropTypes.number,
    end: PropTypes.number,
    disableFuture: PropTypes.bool,
};
