import { ROUTES } from 'services/utils/enums';
import { sendRequest } from '../utils/api';

export const login = async function( params ) {
    return sendRequest( ROUTES.LOGIN, '', params.data );
};

export const refreshToken = async function ( params ) {
    return sendRequest( ROUTES.REFRESH_TOKEN, '', params.data );
};

export const resetPassword = async function ( params ) {
    return sendRequest( ROUTES.FORGOT_PASSWORD, '', params.data );
};

export default {
    login,
    refreshToken,
    resetPassword,
};
