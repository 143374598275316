import { STATUSES } from 'services/utils/enums';
import TYPES from './types';

export const INIT = function () {
    return INITIAL_STATE;
};

const defaultFilters = {
    locations: [],
    repairers: [],
    'service_types': [],
};

export const INITIAL_STATE = {
    locations: [],
    selectedLocations: [],
    count: {
        'num_jobs': null,
        'num_jobs_containing_status': {
            'Open': 0,
            'Accepted': 0,
            'In Progress': 0,
            'Completed': 0,
            'Closed': 0,
            'Declined': 0,
            'Canceled': 0,
        },
    },
    repairers: [],
    selectedRepairers: [],
    statuses: STATUSES,
    selectedStatuses: [],
    supplements: [
        {
            id: 1,
            name: 'Supplement Approved',
        },
        {
            id: 2,
            name: 'Supplement Pending',
        },
        {
            id: 3,
            name: 'Rework Approved',
        },
        {
            id: 4,
            name: 'Rework Pending',
        },
    ],
    selectedServices: [],
    jobs:[],
    activeJob: null,
    repairOrders:[],
    activeRepairOrder: 0,
    repairOrder: {},
    loaded: false,
    searchTerm: '',
    filters: defaultFilters,
    quickFilters: {
        'service_statuses': null,
    },
    dateFilter: {},
    sort: 'desc',
    paging: {},
};

export const JobReducer = function (state, action) {
    console.log('=============== JobReducer: ', action.type, action.payload, state);
    switch (action.type) {
        case TYPES.SET_SEARCH_TERM: {
            return { ...state, searchTerm: action.payload };
        }
        case TYPES.LOAD_LOCATIONS: {
            return { ...state, locations: action.payload };
        }
        case TYPES.LOAD_REPAIRERS: {
            return { ...state, repairers: action.payload };
        }
        case TYPES.SELECT_LOCATIONS: {
            return { ...state, selectedLocations: action.payload };
        }
        case TYPES.CLEAR_JOBS: {
            return { ...state, jobs: [] };
        }
        case TYPES.LOAD_JOBS: {
            const newState = {
                ...state,
                job: {},
                jobs: sortJobs(joinJobLists(state.jobs, action.payload), state.sort),
            };

            if (!state.activeJob && newState.jobs.length > 0) {
                newState.activeJob = newState.jobs[0].id;
            }

            return newState;
        }
        case TYPES.ADD_JOBS: {
            return {
                ...state,
                job: {},
                jobs: sortJobs(joinJobLists(state.jobs, action.payload), state.sort),
            };
        }
        case TYPES.REFRESH_JOB: {
            return {
                ...state,
                jobs: sortJobs(joinJobLists(state.jobs, [action.payload]), state.sort),
            };
        }
        case TYPES.GET_ROS: {
            return { ...state, repairOrders: action.payload };
        }
        case TYPES.SET_ACTIVE_JOB: {
            return { ...state, activeJob: action.payload };
        }
        case TYPES.SET_COUNT: {
            return { ...state, count: action.payload };
        }
        case TYPES.SET_PAGING: {
            return { ...state, paging: action.payload };
        }
        case TYPES.LOADING: {
            return { ...state, loaded: !action.payload };
        }
        case TYPES.SET_FILTER_OPTIONS: {
            const newFilters = action.payload;
            const oldFilters = state.filters;

            for (const [key, newFilterOptions] of Object.entries(newFilters)) {
                if (oldFilters[key]) {
                    const oldFilterOptions = oldFilters[key];

                    for (const option of newFilterOptions) {
                        const oldOption = oldFilterOptions.find((oldOption) => oldOption.value === option.value);

                        if (oldOption) {
                            option.selected = oldOption.selected;
                        }
                    }
                }
            }

            return { ...state, filters: { ...state.filters, ...newFilters } };
        }
        case TYPES.UPDATE_FILTERS: {
            const { propName, selectedOptions } = action.payload;
            const newFilters = { ...state.filters };
            const newOptions = [];

            for (const option of newFilters[propName]) {
                const newOption = { ...option };

                if (selectedOptions.some((selectedFilter) => selectedFilter.value === option.value)) {
                    newOption.selected = true;
                } else {
                    newOption.selected = false;
                }

                newOptions.push(newOption);
            }

            newFilters[propName] = newOptions;

            return { ...state, filters: newFilters, activeJob : null, paging: {} };
        }
        case TYPES.UPDATE_QUICK_FILTERS: {
            const { propName, selectedValues } = action.payload;
            const newFilters = { ...state.quickFilters, [propName]: selectedValues };

            return { ...state, quickFilters: newFilters, activeJob : null, paging: {} };
        }
        case TYPES.UPDATE_DATE_FILTER: {
            const newDateFilter = { ...state.dateFilter, ...action.payload };

            return { ...state, dateFilter: newDateFilter, activeJob : null, paging: {} };
        }
        case TYPES.UPDATE_SORT: {
            return { ...state, sort: action.payload, activeJob : null, paging: {} };
        }
        case TYPES.UPDATE_SEARCH_TERM: {
            return { ...state, searchTerm: action.payload.searchTerm, activeJob : null, paging: {} };
        }
        case TYPES.CLEAR_FILTERS_SEARCH_SORT: {
            const unselectedFilters = {};

            for (const [key, value] of Object.entries(state.filters)) {
                unselectedFilters[key] = value.map((filter) => ({ ...filter, selected: false }));
            }

            return {
                ...state,
                searchTerm: '',
                filters: unselectedFilters,
                sort: 'desc',
                activeJob : 0,
                paging: {},
            };
        }
        case TYPES.RESET: {
            return INIT();
        }
        default: {
            return { ...state };
        }
    }
};

const joinJobLists = (oldJobs= [], newJobs = []) => {
    if (!newJobs.length) {
        return oldJobs;
    }

    if (!oldJobs.length) {
        return newJobs;
    }

    const newJobsMap = newJobs.reduce((acc, job) => {
        acc[job.id] = {
            added: false,
            job,
        };

        return acc;
    }, {});

    const JoinedJobsList = oldJobs.map(job => {
        if (newJobsMap[job.id]) {
            newJobsMap[job.id].added = true;
            return newJobsMap[job.id].job;
        }
        return job;
    });

    for (const job of Object.values(newJobsMap)) {
        if (!job.added) {
            JoinedJobsList.push(job.job);
        }
    }

    return JoinedJobsList;
};

const sortJobs = (jobs, sort) => {
    return jobs.sort((a, b) => {
        const dateA = (new Date(a.created_at)).valueOf();
        const dateB = (new Date(b.created_at)).valueOf();

        return (sort === 'desc' ? -1 : 1) * (dateA - dateB);
    });
};

export default JobReducer;
