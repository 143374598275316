export default {
    SET_SEARCH_TERM: 'set_search',
    RESET_SEARCH: 'reset_search',
    SET_START_DATE: 'set_start_date',
    SET_END_DATE: 'set_end_date',
    LOAD_LOCATIONS: 'load_locations',
    LOAD_REPAIRERS: 'load_repairers',
    SELECT_LOCATIONS: 'select_locations',
    GET_ROS: 'get_repairOrders',
    CLEAR_JOBS: 'clear_jobs',
    LOAD_JOBS: 'LOAD_JOBS',
    ADD_JOBS: 'add_jobs',
    SET_ACTIVE_JOB: 'SET_ACTIVE_JOB',
    SET_COUNT: 'set_count',
    SET_PAGING: 'set_paging',
    RESET: 'reset',
    LOADING: 'LOADING',
    REFRESH_JOB: 'refresh_job',
    SET_FILTER_OPTIONS: 'SET_FILTER_OPTIONS',
    UPDATE_FILTERS: 'UPDATE_FILTERS',
    UPDATE_QUICK_FILTERS: 'UPDATE_QUICK_FILTERS',
    UPDATE_DATE_FILTER: 'UPDATE_DATE_FILTER',
    UPDATE_SORT: 'UPDATE_SORT',
    CLEAR_FILTERS_SEARCH_SORT: 'CLEAR_FILTERS_SEARCH_SORT',
};