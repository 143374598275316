import React, { createContext, useContext, useReducer } from 'react';
import PropTypes from 'prop-types';

import * as actions from './actions';
import JobReducer, { INITIAL_STATE, INIT } from './reducer';

const JobContext = createContext({
    DISPATCH: null,
    state: {},
    actions: {},
});

export const JobsProvider = ({ children }) => {
    const [state, dispatch] = useReducer(JobReducer, INITIAL_STATE, INIT);

    const value = {
        state,
        // @ts-ignore
        DISPATCH: (data) => { dispatch(data); },
        actions: {
            getJobById: actions.getJobById,
            loadLocations: actions.loadLocations(dispatch),
            loadRepairers: actions.loadRepairers(dispatch),
            loadJobCount: actions.loadJobCount(dispatch),
            loadJobs: actions.loadJobs(dispatch),
            loadMoreJobs: actions.loadMoreJobs(dispatch),
            refreshJob: actions.refreshJob(dispatch),
        },
    };

    return (
        <JobContext.Provider value={value}> { children } </JobContext.Provider>
    );
};

JobsProvider.propTypes = {
    children: PropTypes.node,
};

export const useJobs = () => useContext(JobContext);
