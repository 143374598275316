import React, { useEffect, useState } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';

import Loading from 'components/Loading';

const AuthenticatedRoute = ({ user }) => {
    const [loading, setLoading] = useState(true);
    const browserLocation = useLocation();

    useEffect(() => {
        if (user.email) {
            setLoading(false);
        } else {
            setTimeout(() => setLoading(false), 1000);
        }
    }, []);

    if (!user.email) {
        if (loading) {
            return (
                <Loading loading={true} />
            );
        }

        return (
            <Navigate to={`/login?target=${browserLocation.pathname}`} />
        );
    }

    return (<Outlet />);
};

AuthenticatedRoute.propTypes = {
    user: PropTypes.object,
};

export default AuthenticatedRoute;