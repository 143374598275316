// @ts-nocheck
import jobsAPI from './api';
import TYPES from './types';

import { getLocations } from 'services/location/actions';
import { actions as repairerActions } from 'services/repairer';

export const getJobById = (id) => {
    return jobsAPI.getJobById(id);
};

export const loadLocations = (dispatch) => async function() {
    const locations = await getLocations();
    if (dispatch && locations && locations.length) {
        dispatch({ type: TYPES.LOAD_LOCATIONS, payload: locations });
    }
};

export const loadRepairers = (dispatch) => async function() {
    const repairers = await repairerActions.getRepairers();
    if (dispatch && repairers && repairers.length) {
        dispatch({ type: TYPES.LOAD_REPAIRERS, payload: repairers });
    }
};

export const loadJobCount = (dispatch) => async function(errorContext) {
    try {
        const response = await jobsAPI.getJobCount();
        if (response?.data?.status === 200 && response.data.payload) {
            dispatch({ type: TYPES.SET_COUNT, payload: response.data.payload });
        }
    } catch (error) {
        console.log(error);
        if (errorContext) {
            errorContext.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    }
};

export const loadJobs = (dispatch) => async function(requestOptions, errorContext) {
    dispatch({ type: TYPES.LOADING, payload: true });
    dispatch({ type: TYPES.CLEAR_JOBS, payload: [] });

    try {
        const response = await jobsAPI.getJobs(requestOptions);

        if (response?.data?.status === 200 && response.data.payload.length) {
            if (response.data.paging) {
                dispatch({
                    type: TYPES.SET_PAGING,
                    payload: response.data.paging,
                });
            }

            dispatch({
                type: TYPES.LOAD_JOBS,
                payload: response.data.payload.filter(el => el),
            });
        } else if (response?.data?.status === 200 && !response.data.payload.length) {
            dispatch({ type: TYPES.LOAD_JOBS, payload: [] });
        }

        dispatch({ type: TYPES.LOADING, payload: false });
    } catch (error) {
        if (error?.response?.status && error.response.status === 404) {
            dispatch({ type: TYPES.LOAD_JOBS, payload: [] });
        }
        console.error('Error getting jobs:', error);
        if (errorContext) {
            errorContext.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    }
};

export const loadMoreJobs = (dispatch) => async function(url, requestOptions, errorContext) {
    try {
        const response = await jobsAPI.getJobs({ url, ...requestOptions });

        if (response?.data?.status === 200 && response.data.payload.length) {
            if (response.data.paging) {
                dispatch({
                    type: TYPES.SET_PAGING,
                    payload: response.data.paging,
                });
            }

            dispatch({
                type: TYPES.ADD_JOBS,
                payload: response.data.payload.filter(el => el),
            });
        } else if (response?.data?.status === 200 && !response.data.payload.length) {
            // dispatch({ type: TYPES.ADD_JOBS, payload: [] });
        }
    } catch (error) {
        console.error('Error loading more jobs:', error);
        if (errorContext) {
            errorContext.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    }
};

export const refreshJob = (dispatch) => async function(id, errorContext) {
    try {
        const response = await getJobById(id);
        if (response?.data?.status === 200 && response.data.payload) {
            dispatch({ type: TYPES.REFRESH_JOB, payload: response.data.payload });
        }
    } catch (error) {
        console.error('Error refreshing job:', error);
        if (errorContext) {
            errorContext.DISPATCH({ type: 'API_ERROR', payload: error });
        }
    }
};