import { apiPortal } from '../../config';

export const sendRequest = async (route, urlPathParam = '', body = null, config = {}, paramsList = []) => {
    const { method, path, action, isRodx } = route;
    const queryParams = paramsList.filter((p) => p != null && p.length).join('&');
    // eslint-disable-next-line no-extra-boolean-cast
    const fullPath = `${isRodx ? '/rodx' : ''}${path}${urlPathParam?.length ? '/' : ''}${urlPathParam}${!!action ? action : ''}${queryParams.length ? '?' : ''}${queryParams}`;

    //const res = await apiRodx[method](fullPath, body, config);
    const res = await apiPortal[method](fullPath, body, config);

    return res;
};

export const sendBasicRequest = async (method, url, body = null, config = {}) => {
    return await apiPortal[method](url, body, config);
};