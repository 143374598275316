import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { Box, Grid, Typography, IconButton } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';

import styles from './styles';
import { ArrowBack } from '@mui/icons-material';

const PasswordResetEmailSentScreen = () => {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { state } = useLocation();

    // login using email/password on form submit
    const handleSubmit = () => {
        navigate('/login');
    };

    const handleClickBack = () => {
        navigate(-1);
    };

    const handleMouseDownBack = (event) => {
        event.preventDefault();
    };

    const handleMouseUpBack = (event) => {
        event.preventDefault();
    };

    return (
        <Grid container item alignItems='center' justifyContent='center' gap='44px'>
            <Grid container item direction='column' alignItems='center' justifyContent='center'>
                <Grid container item alignItems='center' justifyContent='space-between'>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label='back'
                            onClick={handleClickBack}
                            onMouseDown={handleMouseDownBack}
                            onMouseUp={handleMouseUpBack}
                            edge='end'
                            disabled={loading}
                        >
                            <ArrowBack style={styles.backButton} fontSize='small' />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <Typography align='center' variant='h3' sx={styles.logoText}>
                            Check your inbox
                        </Typography>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
                <span role='img' aria-label='incoming envelope' style={styles.incomingEnvelopeImg}>
                    {String.fromCodePoint('0x1F4E8')}
                </span>
            </Grid>
            <Box display='flex' alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap='32px'>
                <Typography align='center' variant='body1'>
                    An email has been sent to '{ state.email }'.
                </Typography>
                <Typography align='center' variant='body1'>
                    If this is a registered account, you can follow the instructions to reset your password.
                </Typography>
                <LoadingButton
                    type='submit'
                    color='secondary'
                    variant='contained'
                    loading={loading}
                    fullWidth
                    onClick={handleSubmit}
                    sx={{
                        borderRadius: '8px',
                        borderWidth: '1px',
                        paddingLeft: '24px',
                        paddingRight: '24px',
                        fontWeight: '700',
                        fontSize: '14px',
                        height: '40px',
                    }}
                >
                    BACK TO SIGN IN
                </LoadingButton>
            </Box>
        </Grid>
    );
};

export default PasswordResetEmailSentScreen;