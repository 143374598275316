//import { apiRodx } from '../../config';
import { ROUTES } from 'services/utils/enums';
import { sendRequest } from 'services/utils/api';

export const attachMediaToService = async function(params) {
    return sendRequest(ROUTES.SERVICE_ATTACH_MEDIA, params.serviceID, params.data);
};

export const cancelService = async function(params) {
    return sendRequest(ROUTES.SERVICE_CANCEL, params.serviceID);
};

export const approveSupplementService = async function(params) {
    return sendRequest(ROUTES.SERVICE_ACCEPT_SUPPLEMENT, params.serviceID);
};

export const declineSupplementService = async function(params) {
    return sendRequest(ROUTES.SERVICE_REJECT_SUPPLEMENT, params.serviceID);
};

export const requestReworkService = async function(params) {
    return sendRequest(ROUTES.SERVICE_REQUEST_REWORK, params.serviceID, params.data);
};
