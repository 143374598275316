import React, { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { initializeApp } from 'firebase/app';

import { NotificationsProvider } from 'services/notification';
import { AuthProvider } from 'services/auth';

import { ColorModeProvider } from './ColorModeContext';
import { LoadingProvider } from './LoadingContext';
import { firebaseAppConfig } from '../config';
import { ErrorProvider } from './ErrorContext';

export const AppContext = createContext({});

export const AppProvider = ({ children }) => {
    const [app, setApp] = useState(null);

    useEffect(() => {
        setApp(initializeApp(firebaseAppConfig));
    }, []);

    // NotificationProvider is used here to allow use of notifications inside AppContext (child)
    const appElem = app ? (
        <AuthProvider>
            <NotificationsProvider>
                {children}
            </NotificationsProvider>
        </AuthProvider>
    ) : null;

    return (
        <ErrorProvider>
            <AppContext.Provider value={app}>
                <ColorModeProvider>
                    <LoadingProvider>
                        {appElem}
                    </LoadingProvider>
                </ColorModeProvider>
            </AppContext.Provider>
        </ErrorProvider>
    );
};

AppProvider.propTypes = {
    children: PropTypes.node,
};

export const useApp = () => useContext(AppContext);
