import TYPES from './types';

export const INITIAL_STATE = {
    email: null,
    idToken: null,
    refreshToken: null,
    expiresAt: null,
};

export const INIT = function () {
    return { ...INITIAL_STATE };
};

const AuthReducer = function (state, action) {
    switch (action.type) {
        case TYPES.INIT: {
            return { ...INITIAL_STATE };
        }
        case TYPES.LOGIN: {
            return { ...state, email: action.payload.email, idToken: action.payload.idToken, refreshToken: action.payload.refreshToken, expiresAt: action.payload.expiresAt };
        }
        case TYPES.LOGOUT: {
            return { ...state, email: null, idToken: null, refreshToken: null, expiresAt: null };
        }
        case TYPES.REFRESH_TOKEN: {
            return { ...state, idToken: action.payload.idToken, refreshToken: action.payload.refreshToken, expiresAt: action.payload.expiresAt };
        }
        case TYPES.RESET_PASSWORD: {
            return { ...state };
        }
        default: {
            return { ...state };
        }
    }
};

export default AuthReducer;