// @ts-nocheck
import authAPI from './api';
import TYPES from './types';

export const login = (dispatch) => async (email, password, errContext) => {
    try {
        const res = await authAPI.login({ data: { email: email, password: password } });

        // I'd expect a 200 response here, but the API gives us 201
        if (res?.status === 200 || res?.status === 201) {
            const data = res?.data?.data;

            dispatch({ type: TYPES.LOGIN, payload: { email: data.user, idToken: data.idToken, refreshToken: data.refreshToken, expiresAt: data.expiresAt } });
        }
    } catch (error) {
        if (errContext) {
            errContext.DISPATCH({ type: 'API_ERROR', payload: error });
        } else {
            throw new Error(`Error logging in: ${JSON.stringify(error)}`);
        }
    }
};

export const logout = (dispatch) => async (errContext) => {
    // We don't don't tell the API about this at the moment
    dispatch({ type: TYPES.LOGOUT });
};

export const refreshToken = (dispatch) => async (refreshToken, errContext) => {
    try {
        const res = await authAPI.refreshToken({ data: { refreshToken: refreshToken } });

        if (res?.status === 200 || res?.status === 201) {
            const data = res?.data?.data;

            dispatch({ type: TYPES.REFRESH_TOKEN, payload: { idToken: data.idToken, refreshToken: data.refreshToken, expiresAt: data.expiresAt } });
        }
    } catch (error) {
        if (errContext) {
            errContext.DISPATCH({ type: 'API_ERROR', payload: error });
        } else {
            throw new Error(`Error refreshing token: ${JSON.stringify(error)}`);
        }
    }
};

export const resetPassword = (dispatch) => async (email, errContext) => {
    try {
        const res = await authAPI.resetPassword( { data: { email: email } } );

        if (res?.status === 200 || res?.status === 201) {
            dispatch({ type: TYPES.RESET_PASSWORD });
        }
    } catch (error) {
        if (errContext) {
            errContext.DISPATCH({ type: 'API_ERROR', payload: error });
        } else {
            throw new Error(`Error resetting password: ${JSON.stringify(error)}`);
        }
    }
};
