import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'services/auth';
import { Box, Grid, Typography, IconButton, OutlinedInput, InputLabel, FormControl, FormHelperText } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import * as yup from 'yup';

import styles from './styles';
import { ArrowBack } from '@mui/icons-material';

const ForgotPasswordScreen = () => {
    const [loading, setLoading] = useState(false);
    const [emailTouched, setEmailTouched] = useState(false);

    const auth = useAuth();
    const navigate = useNavigate();

    const handleSubmit = ({ email }) => {
        setLoading(true);
        auth.actions.resetPassword(email);
        navigate('/login/password_reset_email_sent', { state: { email } });
        setLoading(false);
    };

    const handleClickBack = () => {
        navigate(-1);
    };

    const handleMouseDownBack = (event) => {
        event.preventDefault();
    };

    const handleMouseUpBack = (event) => {
        event.preventDefault();
    };

    const isReadyToSubmit = (errors) => {
        return emailTouched && !errors.email;
    };

    return (
        <Grid container item alignItems='center' justifyContent='center' gap='44px'>
            <Grid container item direction='column' alignItems='center' justifyContent='center'>
                <Grid container item alignItems='center' justifyContent='space-between'>
                    <Grid item xs={1}>
                        <IconButton
                            aria-label='back'
                            onClick={handleClickBack}
                            onMouseDown={handleMouseDownBack}
                            onMouseUp={handleMouseUpBack}
                            edge='end'
                            disabled={loading}
                        >
                            <ArrowBack style={styles.backButton} fontSize='small' />
                        </IconButton>
                    </Grid>
                    <Grid item xs>
                        <Typography align='center' variant='h3' sx={styles.logoText}>
                            Forgot Password
                        </Typography>
                    </Grid>
                    <Grid item xs={1} />
                </Grid>
                <span role='img' aria-label='locked' style={styles.lockedImg}>
                    {String.fromCodePoint('0x1F512')}
                </span>
            </Grid>
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialValues}
                validationSchema={checkoutSchema}
                validateOnChange={true}
                validateOnBlur={false}
            >
                {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                    <form onSubmit={handleSubmit} style={{ minWidth: '100%' }}>
                        <Box display='flex' alignItems={'center'} justifyContent={'center'} flexDirection={'column'} gap='32px'>
                            <Typography align='center' variant='body1'>
                                Confirm your email and we will send you instructions to reset your password.
                            </Typography>
                            <FormControl variant='outlined' error={!!touched.email && !!errors.email} fullWidth disabled={loading}>
                                <InputLabel htmlFor='outlined-email'>Enter Email</InputLabel>
                                <OutlinedInput
                                    id='outlined-email'
                                    label='Enter Email'
                                    inputProps={{
                                        onBlur: handleBlur,
                                    }}
                                    onChange={e => {
                                        setEmailTouched(true);
                                        handleChange(e);
                                    }}
                                    value={values.email}
                                    name='email'
                                    type='text'
                                    aria-describedby='email-helper-text'
                                />
                                <FormHelperText
                                    id='email-helper-text'
                                >
                                    {touched.email && errors.email}
                                </FormHelperText>
                            </FormControl>
                            <LoadingButton
                                type='submit'
                                color='secondary'
                                variant={isReadyToSubmit(errors) ? 'contained' : 'outlined'}
                                loading={loading}
                                fullWidth
                                sx={{
                                    borderRadius: '8px',
                                    borderWidth: '1px',
                                    paddingLeft: '24px',
                                    paddingRight: '24px',
                                    fontWeight: '700',
                                    fontSize: '14px',
                                    height: '40px',
                                }}
                            >
                                SEND RESET EMAIL
                            </LoadingButton>
                        </Box>
                    </form>
                )}
            </Formik>
        </Grid>
    );
};

const initialValues = {
    email: '',
};

const checkoutSchema = yup.object().shape({
    email: yup.string().email('invalid email').required('required'),
});

export default ForgotPasswordScreen;