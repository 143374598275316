import { ROUTES } from 'services/utils/enums';
import { sendBasicRequest, sendRequest } from 'services/utils/api';

const formatDateParam = (date) => {
    // return date in format 'YYYY-MM-DD'
    console.log('formatting param date: ', date, new Date(date), new Date(date).toISOString(), new Date(date).toISOString().split('T')[0]);
    return new Date(date).toISOString().split('T')[0];
};

export const getJobs = async function({
    url,
    quickFilters = {},
    filters = {},
    dateFilter = { start: null, end: null },
    searchTerm = '',
    sort = 'desc',
}) {
    console.log('++++++++++++++ getJobs: ', filters);
    let result;
    let paramsList = [];

    if (url?.length) {
        // TODO: do something nicer here
        result = sendBasicRequest('get', `/rodx${url}`);
    } else {
        // Sorting
        const sortParam = `ordering=${sort}`;

        // Paging
        const pagingParam = 'page_size=5';

        // Search
        const searchParam = searchTerm?.length ? `search=${searchTerm.split(' ').join(',')}` : null;

        // Quick Filters (service status filters)
        const quickFiltersParam = Object.keys(quickFilters).map((key) => {
            return quickFilters[key] != null && quickFilters[key]?.length ? `${key}=${quickFilters[key]}` : '';
        }).join('&');

        // Date Filters
        const startDateParam = dateFilter?.start ? `start_date=${formatDateParam(dateFilter.start)}` : '';
        const endDateParam = dateFilter?.end ? `end_date=${formatDateParam(dateFilter.end)}` : '';

        // Other Filters
        const otherFilters = Object.keys(filters).map((key) => {
            const options = filters[key];
            const selectedOptions = options.filter((f) => f.selected).map((f) => f.value);

            if (!selectedOptions.length) {
                return '';
            }

            // TODO: this might need to be changed to a different format per filter type to match the API
            // but for now, all used filters have the same format (comma separated values)
            const selectedOptionsString = selectedOptions.join(',');

            return `${key}=${selectedOptionsString}`;
        }).filter((filterStr) => filterStr?.length).flat();

        console.log('------------- otherFilters: ', otherFilters);

        paramsList = [sortParam, searchParam, quickFiltersParam, pagingParam, startDateParam, endDateParam, ...otherFilters];
        result = sendRequest(ROUTES.GET_ALL_JOBS, '', null, {}, paramsList);
    }

    return result;
};

export const getJobCount = async function() {
    return sendRequest(ROUTES.GET_JOB_COUNT);
};

export const getJobById = async function(id) {
    return sendRequest(ROUTES.GET_JOB, id);
};

export const createJob = async function(params) {
    return sendRequest(ROUTES.CREATE_JOB, '', params.data);
};

export const updateJob = async function(params) {
    return sendRequest(ROUTES.UPDATE_JOB, params.id, params.data);
};

export default {
    getJobs,
    getJobCount,
    getJobById,
    createJob,
    updateJob,
};
