import styles from '../styles';

const colors = {
    ...styles.colors,
    textEnabled: '#808B90',
    textPopulated: '#141E25',
    textError: '#E80604',
    textFocused: '#307FE2',
    textDisabled: '#CAD2D9',
    buttonEnabled: '#307FE2',
    buttonProcessing: '#D6E8FC',
    buttonDisabled: '#E2E8EE',
    backgroundTop: '#ECF0F5',
    backgroundBottom: '#FFFFFF',
};

const background = {
    background: 'linear-gradient(180deg, #ECF0F5 41.56%, #FFFFFF 100%)',
    minWidth: '100vw',
    minHeight: '100vh',
};

const appBar = {
    padding: '16px 24px',
};

const rodLogoImg = {
    padding: '4px',
};

const menu = {
    background: 'white',
    padding: '2% 7% 3%',
    marginTop: '2%',
    borderRadius: '16px',
    borderColor: '#ECF0F5',
    borderWidth: '1px',
};

export default {
    ...styles,
    colors,
    background,
    appBar,
    rodLogoImg,
    menu,
};
