import React, { useEffect } from 'react';
import { Grid, Paper, Typography, Divider, useTheme } from '@mui/material';

import { useError } from 'contexts/ErrorContext';
import { useLocations, TYPES as LOCATION_TYPES } from 'services/location';
import { getValidationMessage } from 'services/utils/validation';

import LocationForm from './LocationForm';
import MultiAccordionContainer from 'components/Containers/Accordion/MultiAccordionContainer';

const LocationDetails = () => {
    const errorContext = useError();
    const theme = useTheme();
    const locationContext = useLocations();
    const activeLocation = locationContext?.state?.activeLocationId ?
        locationContext.state.locations.find((loc) => loc.id === locationContext.state.activeLocationId) :
        {};

    const handlePropChange = (propName, propValue) => {
        locationContext.DISPATCH({
            type: LOCATION_TYPES.EDIT_LOCATION_PROP,
            payload: { propName, propValue },
        });
    };

    const handleContactChange = (propName, propValue) => {
        locationContext.DISPATCH({
            type: LOCATION_TYPES.EDIT_LOCATION_CONTACT_PROP,
            payload: { propName, propValue },
        });
    };

    const handleAddressChange = (place) => {
        locationContext.DISPATCH({
            type: LOCATION_TYPES.EDIT_LOCATION_ADDRESS,
            payload: place,
        });
    };

    const undoLocationChanges = () => {
        locationContext.actions.refreshLocation(locationContext?.state?.activeLocationId);
    };

    const saveUpdatedLocation = () => {
        locationContext.actions.updateLocation(activeLocation, errorContext);
    };

    const errorMessage = getValidationMessage(locationContext?.state?.validations || {});

    useEffect(() => {
        locationContext.actions.validateActiveLocation(locationContext);
    }, [activeLocation]);

    const accordions = [
        {
            summary: 'DETAILS',
            content: (
                <LocationForm
                    location={activeLocation}
                    handlePropChange={handlePropChange}
                    handleContactChange={handleContactChange}
                    handleAddressChange={handleAddressChange}
                    validations={locationContext?.state?.validations || {}}
                    showCancelBtn={true}
                    showSubmitBtn={true}
                    cancelBtnDisabled={!activeLocation?.edited}
                    submitBtnDisabled={
                        !activeLocation?.edited || Object.values(locationContext?.state?.validations || {}).length > 0
                    }
                    onSubmit={saveUpdatedLocation}
                    onCancel={undoLocationChanges}
                    submitBtnTooltip={errorMessage}
                />
            ),
        },
    ];

    const accordionsDefaultOpenState = {
        'DETAILS': true,
    };

    return (
        <Paper elevation={0} sx={{ width: '100%' }}>
            <Grid container justifyContent={'center'} display={'flex'} sx={{ pb: 4 }}>
                <Grid
                    item
                    sx={{ height: '50px', width: '100%', px: 2 }}
                    alignItems={'center'}
                    display={'flex'}
                    justifyContent={'space-between'}
                >
                    <Typography sx={{ fontSize: 14, fontWeight: 500 }} color={theme.palette.grey[900]}>Details</Typography>
                </Grid>
                <Divider sx={{ width: '100%', mb: 1 }} />
                <Grid container>
                    <Grid item xs={2}></Grid>
                    <Grid container item xs={8} direction={'column'} spacing={1}>
                        <Grid item>
                            <Typography textAlign={'center'} my={2.5} sx={{ width: '100%', px: 2, fontSize: 20, fontWeight: 400 }}>
                                { activeLocation?.nickname?.length > 0 ? activeLocation.nickname : 'Location Nickname' }
                            </Typography>
                        </Grid>
                        <MultiAccordionContainer accordions={accordions} defaultState={accordionsDefaultOpenState} multiExpand />
                    </Grid>
                    <Grid item xs={1}></Grid>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default LocationDetails;