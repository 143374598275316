import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useLocation as useBrowserLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';

import {
    Box,
    Button,
    Grid,
    Paper,
    Typography,
    styled,
    useTheme,
    Divider,
} from '@mui/material';

import { ControlPoint as ControlPointIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import CarRepairOutlinedIcon from '@mui/icons-material/CarRepairOutlined';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SortIcon from '@mui/icons-material/Sort';

import pushManager from 'serviceWorker/pushManager';

import { useJobs, TYPES as JOB_TYPES } from 'services/job';
import { useLocations } from 'services/location';
import { useError } from 'contexts/ErrorContext';
import { SERVICES } from 'services/utils/enums';

import Loading from 'components/Loading';
import SearchAndFilterPanel from 'components/Panels/SearchAndFilterPanel';
import QuickFiltersPanel from 'components/Panels/QuickFiltersPanel';
import SubFiltersPanel from 'components/Panels/QuickFiltersPanel/SubFiltersPanel';

import JobList from './content/JobList';
import JobDetails from './content/JobDetails';
import './styles.css';

const ContentContainer = styled(Box)(({ theme }) => ({
    backgroundColor: theme.palette.background.paper,
    borderRadius: '8px',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(2),
    width: '100%',
}));

const NewJobButton = styled(Button)(({ theme }) => ({
    borderRadius: '8px',
    background: theme.palette.blue.main,
    color: 'white',
    '& .MuiButton-startIcon': {
        color: theme.palette.blue[300],
    },
}));

const filtersElementsDef = {
    locations: {
        title:'Locations',
        options: [],
        IconComponent: LocationOnIcon,
    },
    repairers: {
        title: 'Repairers',
        options: [],
        IconComponent: BuildCircleOutlinedIcon,
    },
    'service_types': {
        title: 'Service',
        options: [],
        IconComponent: CarRepairOutlinedIcon,
    },
};

const generateFilterElementsDef = (filterOptions) => {
    console.log('filterOptions', filterOptions);
    return Object.entries(filterOptions).reduce((acc, [id, options]) => {
        if (filtersElementsDef[id]) {
            acc[id] = { ...filtersElementsDef[id], options };
        }
        return acc;
    }, {});
};

const defaultSortConfig = {
    options: [
        {
            id:'desc',
            name: 'Newest',
            icon: () => <SortIcon style={{ transform: 'scaleY(1)' }} />,
        },
        {
            id:'asc',
            name: 'Oldest',
            icon: () => <SortIcon style={{ transform: 'scaleY(-1)' }} />,
        },
    ],
    title: 'Sort',
    IconComponent: SortIcon,
};

const quickFilters = [ 'All Jobs', 'Open', 'Closed'];

const quickFiltersToStatusMap = {
    'All Jobs' : null,
    'Open': ['Open', 'Accepted', 'In Progress', 'Declined'],
    'Closed': ['Completed', 'Closed', 'Canceled'],
};

const subFiltersMap = {
    'All Jobs': [],
    'Open': ['Open', 'Accepted', 'In Progress'],
    'Closed': ['Completed', 'Canceled'],
};

const subFiltersToStatusMap = {
    'Open': ['Open'],
    'Accepted': ['Accepted'],
    'In Progress': ['In Progress'],
    'Declined': ['Declined'],
    'Completed': ['Completed', 'Closed'],
    'Canceled': ['Canceled'],
};

const JobsScreen = () => {
    const theme = useTheme();
    const navigate = useNavigate();
    const errorContext = useError();
    const jobsContext = useJobs();
    const browserLocation = useBrowserLocation();
    const locationContext = useLocations();
    const [selectedQuickFilter, setSelectedQuickFilter] = useState(quickFilters[0]);
    const [selectedSubFilter, setSelectedSubFilter] = useState(null);

    console.log('jobsContext', jobsContext, 'locationContext', locationContext);

    const refreshJobs = (searchTerm, sort, filters, quickFilters, dateFilter) => {
        console.log('000000000000000000000000000000000000000000 refreshJobs');
        jobsContext.actions.loadJobs({
            searchTerm,
            sort,
            filters,
            quickFilters,
            dateFilter,
        }, errorContext);
    };

    const debouncedRefreshJobs = useCallback(debounce(refreshJobs, 500), []);

    useEffect(() => {
        // Component Mounting

        // Register a notification callback to automatically update the job
        pushManager.addNotificationCallback((notification) => {
            const jobId = notification?.data?.['job_id'];
            if (jobId) {
                jobsContext.actions.refreshJob(jobId);
            }
        });

        // Refresh filter options in case they changed (locations and repairers)
        jobsContext.actions.loadLocations();
        jobsContext.actions.loadRepairers();

        return () => {
            // Component Unmounting
            // remove selected job so when component is reloaded, it doesn't show the old job
            jobsContext.DISPATCH({ type: JOB_TYPES.SET_ACTIVE_JOB, payload: null });
        };
    }, []);

    useEffect(() => {
        // Explicitly set 'loading' and clear jobs ASAP to reduce screen refresh lag caused by the debounced refreshJobs
        jobsContext.DISPATCH({ type: JOB_TYPES.LOADING, payload: true });
        jobsContext.DISPATCH({ type: JOB_TYPES.CLEAR_JOBS, payload: [] });
        debouncedRefreshJobs(
            jobsContext.state.searchTerm,
            jobsContext.state.sort,
            jobsContext.state.filters,
            jobsContext.state.quickFilters,
            jobsContext.state.dateFilter,
        );
        // if (!jobsContext.state.count || jobsContext.state.count.num_jobs == null) {
        //     jobsContext.actions.loadJobCount(errorContext);
        // }
        if (!jobsContext.state?.locations?.length) {
            jobsContext.actions.loadLocations();
        }
        if (!jobsContext.state?.repairers?.length) {
            jobsContext.actions.loadRepairers();
        }
    }, [
        jobsContext?.state?.filters,
        jobsContext?.state?.quickFilters,
        jobsContext?.state?.dateFilter,
        jobsContext?.state?.searchTerm,
        jobsContext?.state?.sort,
    ]);

    useEffect(() => {
        if (jobsContext.state.locations?.length) {
            jobsContext.DISPATCH({
                type: JOB_TYPES.SET_FILTER_OPTIONS,
                payload: { locations: jobsContext.state.locations.map((loc) => (
                    {
                        value: loc.id,
                        name: loc.nickname || 'location',
                        selected: false,
                    }
                )) },
            });
        }
        if (jobsContext.state.repairers?.length) {
            jobsContext.DISPATCH({
                type: JOB_TYPES.SET_FILTER_OPTIONS,
                payload: { repairers: jobsContext.state.repairers.map((repairer) => (
                    {
                        value: repairer.id,
                        name: repairer.name,
                        selected: false,
                    }
                )) },
            });
        }
        jobsContext.DISPATCH({
            type: JOB_TYPES.SET_FILTER_OPTIONS,
            payload: { 'service_types': SERVICES.map((service) => (
                {
                    value: service.name,
                    name: service.name,
                    selected: false,
                }
            )) },
        });
    }, [jobsContext?.state?.locations, jobsContext?.state?.repairers]);

    useEffect(() => {
        const selectedStatus = selectedSubFilter ? subFiltersToStatusMap[selectedSubFilter] : quickFiltersToStatusMap[selectedQuickFilter];
        const newQuickFilterVal = selectedStatus?.length ? selectedStatus.join(',') : null;

        jobsContext.DISPATCH({
            type: JOB_TYPES.UPDATE_QUICK_FILTERS,
            payload: { propName: 'service_statuses', selectedValues: newQuickFilterVal },
        });
    }, [selectedQuickFilter, selectedSubFilter]);

    const jobsFilters = React.useMemo(() => generateFilterElementsDef(jobsContext.state.filters), [jobsContext.state.filters]);

    const sortConfig = {
        ...defaultSortConfig,
        options: defaultSortConfig.options.map((option) => {
            return { ...option, selected: option.id === jobsContext.state.sort };
        }),
    };
    const handleSortChange = (newSort) => {
        jobsContext.DISPATCH({ type: JOB_TYPES.UPDATE_SORT, payload: newSort });
    };

    const handleJobsFilterChange = (propName, selectedOptions) => {
        jobsContext.DISPATCH({ type: JOB_TYPES.UPDATE_FILTERS, payload: { propName, selectedOptions } });
    };

    const handleSearchTermChange = (searchTerm) => {
        jobsContext.DISPATCH({ type: JOB_TYPES.UPDATE_SEARCH_TERM, payload: { searchTerm } });
    };

    // TOREMOVE
    const jobsListElem = (
        <>
            {/* <Box sx={{ height: '50px', mx: 1 }} alignItems={'center'} display={'flex'} justifyContent={'space-between'}>
                <DropDown
                    label={'Sort'}
                    dispatch={jobsContext.DISPATCH} state={jobsContext.state}
                    multi={false}
                    icon={<SortOutlined
                        width={'20px'} height={'20px'}
                        sx={{ marginRight: '5px', color: theme.palette.blue.main }}
                    />}
                    options={jobsContext.state.sort.map((el) => {
                        return el;
                    })}
                    cb={setFilterParams}
                />
                <IconButton onClick={() => loadJobs() }> <RefreshIcon /> </IconButton>
            </Box> */}
        </>
    );

    const activeJob = jobsContext.state.jobs.find((job) => job.id === jobsContext.state.activeJob);
    const jobsDetailsElem = !jobsContext?.state?.loaded || !activeJob ? (
        <Loading loading fullScreen={false} width={150} height={150} />
    ): (
        <JobDetails activeJob={activeJob} />
    );

    return (
        <Grid container display={'flex'}>
            <Grid item container display={'flex'} justifyContent={'space-between'}>
                <Grid item mb={2}>
                    <Typography variant='h1' display={'flex'} alignItems={'center'}>
                        Jobs
                    </Typography>
                </Grid>
                <Grid item container display={'flex'} justifyContent={'space-between'} mb={2} alignItems={'center'}>
                    <Box display={'flex'}>
                        <QuickFiltersPanel
                            filters={quickFilters}
                            selectedFilter={selectedQuickFilter}
                            onFilterSelected={(selectedFilter) => {
                                setSelectedQuickFilter(selectedFilter);
                                setSelectedSubFilter(null);
                            }}
                        />
                    </Box>
                    <NewJobButton
                        variant='contained'
                        onClick={() => navigate('/new_job')}
                        startIcon={<ControlPointIcon />}
                    >
                        New Job
                    </NewJobButton>
                </Grid>
            </Grid>
            <Grid item container display={'flex'}>
                <ContentContainer sx={{ p:0, mb: 6 }}>
                    {
                        selectedQuickFilter ? (
                            <SubFiltersPanel
                                filters={subFiltersMap[selectedQuickFilter]}
                                selectedFilter={selectedSubFilter}
                                onFilterSelected={setSelectedSubFilter}
                            />
                        ) : null
                    }
                    <SearchAndFilterPanel
                        id={'jobs'}
                        filters={jobsFilters}
                        onFilterChange={handleJobsFilterChange}
                        sortConfig={sortConfig}
                        selectedSort={jobsContext.state.sort ?? 'desc'}
                        onSortChange={handleSortChange}
                        searchTerm={jobsContext.state.searchTerm ?? ''}
                        onSearchTermChange={handleSearchTermChange}
                        searchOptions={['Lemur Lot', 'The Jags']}
                        showDateFilter
                        dateFilter={jobsContext.state.dateFilter}
                        dateFilterOptions={{ disableFuture: true }}
                        onDateRangeChanged={(start, end) => {
                            jobsContext.DISPATCH({ type: JOB_TYPES.UPDATE_DATE_FILTER, payload: { start, end } });
                        }}
                    />
                    <Divider sx={{ color: theme.palette.grey[50], width: '100%' }} />
                    <Grid sx={{ p: 0, mx: 0 }} display={'flex'} minHeight={'800px'} maxHeight={'800px'}>
                        <JobList />
                        <Grid item xs={9} display={'flex'} justifyContent={'space-between'} overflow={'auto'}>
                            {jobsDetailsElem}
                        </Grid>
                    </Grid>
                </ContentContainer>
            </Grid>

        </Grid>
    );
};

export default JobsScreen;
