import React from 'react';
import PropTypes from 'prop-types';

import { useTheme, Grid, Button } from '@mui/material';

export default function QuickFiltersPanel({ filters = [], selectedFilter, onFilterSelected }) {
    const theme = useTheme();

    return !filters?.length ? null : (
        <Grid display={'flex'}>
            { filters.map((filter, i) => (
                <Grid item key={i}>
                    <Button
                        variant={'contained'}
                        onClick={() => onFilterSelected(filter === selectedFilter ? null : filter)}
                        sx={{ py: .5,
                            px: 3,
                            m: 1,
                            fontSize: '16px',
                            textTransform: 'none',
                            fontWeight: 400,
                            color: (filter === selectedFilter) ? theme.palette.white : theme.palette.black,
                            backgroundColor: (filter === selectedFilter) ? theme.palette.blue[800] : theme.palette.grey[50],
                            '&:hover': {
                                color: theme.palette.blue[500],
                                backgroundColor: theme.palette.blue[800],
                            },
                        }}
                    >
                        { filter }
                    </Button>
                </Grid>
            ))}
        </Grid>
    );
}

QuickFiltersPanel.propTypes = {
    filters: PropTypes.array,
    selectedFilter: PropTypes.string,
    onFilterSelected: PropTypes.func,
};
